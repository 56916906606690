import axios from 'axios';
import { FOODS_ADMIN_TOKEN, FOODS_BASE_URL } from '../constants/Urls';

axios.defaults.baseURL = FOODS_BASE_URL;

export const getTemporaryToken = () => {
  const postData = {
    staticToken: FOODS_ADMIN_TOKEN,
  };

  return axios.post('/api/v1/authentication', postData, {
    headers: {
      'content-type': 'application/json,charset=UTF-8',
    },
  });
};

export const createMerchant = (
  tempToken: string,
  name: string,
  email: string,
  phone: string,
  organization: string,
  botKey: string,
) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${tempToken}`;
  const postData = {
    staticToken: FOODS_ADMIN_TOKEN,
    name,
    organization,
    botKey,
    imageUrl: 'https://blipmediastore.blob.core.windows.net/public-medias/Media_f69a99a3-f04a-4fb0-9748-9f1ca36826d5',
    phone,
    email,
  };
  return axios.post('/api/v1/merchant', postData);
};
