import { RESOURCES_ROUTE } from '../constants/ApiRoutes';
import { EventData } from '../typings/EventData';
import { ApiService } from './Api';

type PluginEventsCallbacks = {
  [key: string]: any;
};

enum EventStatus {
  Success = 'Success',
  Error = 'Error',
}

export const PluginEvents: PluginEventsCallbacks = {
  SO_GET_RESOURCES: async (apiService: ApiService, data: EventData) => {
    const botShortName = data.data;

    try {
      const resources = await apiService.get(`${RESOURCES_ROUTE}/${botShortName}`);
      return {
        status: EventStatus.Success,
        data: resources,
      };
    } catch (exception) {
      return {
        status: EventStatus.Error,
        data: exception,
      };
    }
  },

  SO_SET_RESOURCES: async (apiService: ApiService, data: EventData) => {
    const { botShortName, resources } = data.data;
    try {
      apiService.post(`${RESOURCES_ROUTE}/${botShortName}`, resources);
      return {
        status: EventStatus.Success,
      };
    } catch (exception) {
      return {
        status: EventStatus.Error,
        data: exception,
      };
    }
  },

  // SO_NEXT_STEP: () => {
  //   try {
  //     location.href = ROUTE_CONCLUSION;
  //     return {
  //       status: EventStatus.Success,
  //     };
  //   } catch (exception) {
  //     return {
  //       status: EventStatus.Error,
  //       data: exception,
  //     };
  //   }
  // },

  SO_STEP_BACK: () => {
    try {
      history.back();
      return {
        status: EventStatus.Success,
      };
    } catch (exception) {
      return {
        status: EventStatus.Error,
        data: exception,
      };
    }
  },
};
