import Banners from '../../assets/Banners';
import Rectangle from '../../assets/Rectangle.svg';
import { ROUTE_ONBOARDING } from '../../constants/Routes';
import React from 'react';
import { Container } from './styles';

const Banner: React.FC = () => {
  return (
    <Container>
      {location.pathname === ROUTE_ONBOARDING ? (
        <>
          <img src={Banners.BlipFoods} alt="Take Blip" />
          <img src={Rectangle} alt="Elipse" />
        </>
      ) : (
        <img src={Banners.BlipFoodsSmall} alt="Take Blip" />
      )}
    </Container>
  );
};

export default Banner;
