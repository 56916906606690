import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 1100px;
  justify-content: space-between;
  padding: 0.5rem;
  margin: 0 auto;

  img {
    position: absolute;
    right: 50%;
  }
`;
