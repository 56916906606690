import { Buffer } from 'buffer';

export const isNumber = (value: string | number) => {
  return value != null && value !== '' && !isNaN(Number(value.toString()));
};

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
};

export const encodeToken = (token: string | undefined) => {
  if (!token || token.length == 0) {
    return '';
  }

  const tokenReverse = token.split('').reverse().join('');
  return Buffer.from(tokenReverse).toString('base64');
};

export const decodeToken = (token: string | undefined) => {
  if (!token || token.length == 0) {
    return '';
  }

  const decodedToken = Buffer.from(token, 'base64').toString('ascii');
  const reverseToken = decodedToken.split('').reverse().join('');
  return reverseToken;
};

export const handleApiErrorResponseForTrack = (error: any) => {
  const responseData = error?.response?.data;
  return responseData ? (responseData as string) : (error as string);
};
