import BlipCry from '../../../assets/BlipCry.svg';
import React from 'react';
import { Center, PageContainer } from '../../../styles';

const ActivationFailed: React.FC = () => {
  return (
    <PageContainer>
      <div>
        <bds-typo variant="fs-32" bold="bold" tag="h2">
          Ops! Algo deu errado.
        </bds-typo>
        <bds-typo>
          Aconteceu um problema técnico durante a ativação do WhatsApp. Entraremos em contato o mais rápido possível.
          Desculpe o incoveniente!
        </bds-typo>
      </div>
      <Center>
        <img src={BlipCry} alt="Blip Pack" />
      </Center>
    </PageContainer>
  );
};

export default ActivationFailed;
