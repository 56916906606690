import styled from 'styled-components';

export const PageLeftContainer = styled.div`
  #facebookLogin {
    color: #125ad5;
    font-weight: 600;
    cursor: pointer;
  }

  a.disabled {
    pointer-events: none;
    opacity: 0.5;
    text-decoration: none !important;
  }

  div:last-child {
    margin-top: 100px;
  }
`;
