import { ROUTE_EMBEDDED_SIGNUP, ROUTE_ONBOARDING, ROUTE_WHATSAPP_BUSINESS } from '../constants/Routes';
import { SO_SESSION_RECOVERY } from '../constants/TrackingEvents';
import { useAppContext } from '../contexts/AppContext';
import { usePackContext } from '../contexts/PackContext';
import { useCallback, useEffect, useState } from 'react';
import { RestoredSessionData, Session } from '../typings/Session';
import { decodeToken, encodeToken } from '../utils';
import { useTrackNoEvent } from './useTrackNoEvent';

export const useSession = () => {
  const { settings, profile, whatsAppInfo, setWhatsAppInfo } = useAppContext();
  const { skipActivation, setSkipActivation } = useAppContext();
  const { pack, setPack } = usePackContext();
  const { tracking } = useTrackNoEvent();
  const [enableSaveEession, setEnableSaveEession] = useState(false);

  const getSessionName = useCallback(() => {
    const hasEmail = profile?.email && profile.email != '';
    return hasEmail ? `so_session_${profile?.email}` : 'so_session';
  }, [profile?.email]);

  const getSession = useCallback(() => {
    const sessionLocalStorageJson = localStorage.getItem(getSessionName());
    if (sessionLocalStorageJson) {
      const sessionLocalStorage: Session = sessionLocalStorageJson && JSON.parse(sessionLocalStorageJson);
      sessionLocalStorage.whatsAppInfo.inputToken = decodeToken(sessionLocalStorage.whatsAppInfo.inputToken);
      return sessionLocalStorage;
    }
  }, [getSessionName]);

  const restoreSavedSessionValues = useCallback(
    (session: Session) => {
      setPack(session.pack);
      setWhatsAppInfo({ ...session.whatsAppInfo, inputToken: session.whatsAppInfo.inputToken });
      setSkipActivation(session.skipActivation);
    },
    [setPack, setWhatsAppInfo, setSkipActivation],
  );

  const isSessionExpired = useCallback(() => {
    const session = getSession();
    if (session) {
      const updateDate = new Date(session.updateDate);
      updateDate.setDate(updateDate.getDate() + settings.NumberDaysToExpireUserSession);
      return updateDate < new Date();
    }
    return false;
  }, [settings.NumberDaysToExpireUserSession, getSession]);

  const handleRouteForRestoredSession = (session: Session | undefined) => {
    if (session) {
      if (session.lastPage == ROUTE_EMBEDDED_SIGNUP) {
        return ROUTE_WHATSAPP_BUSINESS;
      }
      return session.lastPage;
    }
    return ROUTE_ONBOARDING;
  };

  const saveSession = useCallback(() => {
    if (pack?.id) {
      const session: Session = {
        pack: pack,
        whatsAppInfo: { ...whatsAppInfo, inputToken: encodeToken(whatsAppInfo.inputToken) },
        skipActivation: skipActivation ?? false,
        updateDate: new Date(),
        lastPage: window.location.pathname,
      };
      localStorage.setItem(getSessionName(), JSON.stringify(session));
    }
  }, [pack, whatsAppInfo, skipActivation, getSessionName]);

  const handleSessionRestoreTrack = useCallback(
    (restoredSessionData: RestoredSessionData) => {
      tracking(SO_SESSION_RECOVERY, {
        lastPageUrl: restoredSessionData.session?.lastPage,
        currentPageUrl: restoredSessionData.currentRoute,
        status: restoredSessionData.success ? 'sucesso' : 'falha',
        error: restoredSessionData.errorMessage,
        packId: restoredSessionData.session?.pack.id,
        tenant: restoredSessionData.session?.pack.tenant.id,
        plan: restoredSessionData.session?.pack.tenant.plan?.name,
        merchantName: restoredSessionData.session?.pack.companyName,
        installationId: restoredSessionData.session?.pack.installationId,
      });
    },
    [tracking],
  );

  const deleteSession = useCallback(() => {
    localStorage.removeItem(getSessionName());
  }, [getSessionName]);

  const tryRestoreSession = useCallback(() => {
    const session = getSession();
    const restoredSessionData: RestoredSessionData = {
      errorMessage: '',
      currentRoute: ROUTE_ONBOARDING,
      success: false,
      session: session,
    };

    if (session) {
      if (isSessionExpired()) {
        restoredSessionData.errorMessage = 'Sessão expirada';
      } else {
        const isSameTenant = !pack.tenant.id || pack.tenant.id == session.pack.tenant.id;
        const isSamePackId = !pack.id || pack.id == session.pack.id;
        const isSameSkipActivation = skipActivation == undefined || skipActivation == session.skipActivation;

        if (isSamePackId && isSameTenant && isSameSkipActivation) {
          restoreSavedSessionValues(session);
          restoredSessionData.success = true;
          restoredSessionData.currentRoute = handleRouteForRestoredSession(session);
        } else {
          restoredSessionData.errorMessage = 'Nova instalação';
        }
      }
    }

    handleSessionRestoreTrack(restoredSessionData);
    return restoredSessionData;
  }, [pack, skipActivation, getSession, isSessionExpired, restoreSavedSessionValues, handleSessionRestoreTrack]);

  useEffect(() => {
    if (enableSaveEession) {
      saveSession();
    }
  }, [enableSaveEession, saveSession]);

  return { enableSaveEession, saveSession, deleteSession, tryRestoreSession, setEnableSaveEession };
};
