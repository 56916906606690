import { SO_API_CALL, SO_EMB_SIGNUP_INTERRUPTED } from '../constants/TrackingEvents';
import { Facebook } from './Facebook';
import { logTrack } from './LogTrackService';

const FB = new Facebook();

export const initFacebookSdk = async (facebookAppId: string) => {
  await FB.init({
    appId: facebookAppId,
    cookie: true,
    xfbml: true,
    version: 'v14.0',
  });
};

export const launchWhatsAppSignup = (facebookAppId: string, callback: (arg0: string) => any) => {
  initFacebookSdk(facebookAppId).then(() => {
    logTrack(SO_API_CALL, { apiCall: 'GRAPH API launchWhatsAppSignup', status: 'Sucesso' });
    FB.login(
      function (response: any) {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          callback && callback(accessToken);
        } else {
          logTrack(SO_EMB_SIGNUP_INTERRUPTED);
        }
      },
      {
        auth_type: 'reauthorize',
        scope: 'business_management,whatsapp_business_management',
        extras: {
          feature: 'whatsapp_embedded_signup',
          setup: {
            // Prefilled data can go here
          },
        },
      },
    );
  });
};
