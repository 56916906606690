import styled from 'styled-components';

export const NumberOptions = styled.div`
  display: inline-flex;
  margin-top: 16px;
  gap: 22px;

  bds-paper {
    padding: 10px;
    width: 170px;
    height: 190px;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
  bds-paper:hover {
    box-shadow: rgb(7 71 166 / 12%) 0px 10px 16px; /* Primary */
  }
`;

export const PageContainer = styled.div`
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  color: #202c44;
`;

export const PageConfiguration = styled.div`
  display: inline-block;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  color: #202c44;
  border: #d2dfe6;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  padding: 5%;
  bds-typo {
    margin-bottom: 5%;
  }
`;

export const InputsLeft = styled.div`
  justify-content: center;
  color: #202c44;
  bds-input {
    margin-top: 5%;
    margin-bottom: 10%;
    width: 90%;
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

export const UploadImage = styled.div`
  margin-top: 5%;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  bds-button-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

export const FirstFields = styled.div`
  margin-top: 0.5%;
  margin-bottom: -5.5%;
  display: flex;
  justify-content: space-between;
  bds-input {
    margin-right: 10%;
    margin-left: 7%;
  }
`;

export const InputsRight = styled.div`
  justify-content: center;
  color: #202c44;
  bds-input {
    margin-top: 5%;
    margin-bottom: 13%;
    width: 90%;
  }
`;

export const ConfigurationFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  color: #202c44;
`;

export const TextRadio = styled.span`
  font-size: 1.25rem;
  line-height: 100%;
  font-weight: 700;
  margin: 6px 0;
  display: block;
`;

export const ContainerActions = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 32px;
  width: 76%;
  margin-left: 24%;
  margin-bottom: 6%;
`;
