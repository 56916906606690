import Rocket from '../../assets/Rocket.svg';
import { SO_CONCLUSION_LINK_REDIRECT, SO_FINISHED } from '../../constants/TrackingEvents';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import { Step } from '../../enums';
import { useSession } from '../../hooks/useSession';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import React, { useCallback, useEffect } from 'react';
import { Center, PageContainer } from '../../styles';
import { LeftColumn } from './styles';
import { useAuth } from 'oidc-react';
import { createToastError } from 'Self-onboading/services/Toast';
import { GET_INSTALLATION_BY_SHORTNAME } from 'Self-onboading/constants/ApiRoutes';
import { createMerchant, getTemporaryToken } from 'Self-onboading/services/BlipFoods';
import { PluginEvents } from 'Self-onboading/services/EventExecuter';
import { SO_SET_RESOURCES } from '../../constants/TrackingEvents';

const Conclusion: React.FC = () => {
  const { setActiveStep, apiService } = useAppContext();
  const { pack } = usePackContext();
  const { deleteSession } = useSession();
  const { tracking } = useTrackNoEvent();
  const auth = useAuth();
  const packManagerEnvUrl = process.env.REACT_APP_MANAGER_URL;
  const { saveSession } = useSession();

  const getInstallationInfo = (installation: any) => {
    try {
      return {
        shortName: installation.routerShortName,
        accessKey: installation.routerAccessKey,

        skillTransbordo: {
          shortName: installation.deskShortName,
          accessKey: installation.deskAccessKey,
        },
      };
    } catch {
      createToastError('Erro ao pesquisar instalações', 'Usuário não possui instalações deste pack');
    }
  };

  useEffect(() => {
    saveSession();
    if (pack.plugin?.url) {
      apiService.get(GET_INSTALLATION_BY_SHORTNAME, { shortName: pack.routerShortName }).then((installation: any) => {
        const routerInfo = getInstallationInfo(installation);
        if (routerInfo) {
          try {
            getTemporaryToken().then(async (res: any) => {
              const merchantEmail = auth.userData?.profile.email as string;
              await createMerchant(
                res.data.temporaryToken,
                auth.userData?.profile.Company,
                merchantEmail,
                auth.userData?.profile.PhoneNumber,
                getUrlTenant(pack.tenant.id),
                EncodeBase64(routerInfo!.shortName, Decodebase64(routerInfo!.accessKey)),
              )
                .then(async () => {
                  const resources = [
                    { name: 'Company_email', value: merchantEmail },
                    { name: 'Merchant_phone', value: auth.userData?.profile.PhoneNumber },
                    { name: 'Company_name', value: auth.userData?.profile.Company },
                    { name: 'Merchant_fullname', value: auth.userData?.profile.FullName },
                    { name: 'Company_site', value: auth.userData?.profile.CompanySite },
                  ];
                  await saveTracking('success');
                  await saveInfoToResources(resources);
                  trackAndRedirectToPackManager();
                })
                .catch((e: any) => saveTracking('error', e));
            });
          } catch (e) {
            saveTracking('error', e);
          }
        }
      });
    }
  }, []);

  const saveTracking = async (status: string, error?: any) => {
    await tracking('blippacks-selfonboarding-bf-personal-company-info-save', {
      status,
      error,
    });
  };

  const saveInfoToResources = async (resources: any) => {
    for await (const resource of resources) {
      const { name, value } = resource;
      const data = {
        data: {
          botShortName: pack.routerShortName,
          resources: [
            {
              name,
              value,
              type: 'text/plain',
            },
          ],
        },
      };
      const setPackResources = PluginEvents[SO_SET_RESOURCES];
      await setPackResources(apiService, data);
    }
  };

  const getUrlTenant = (tenant: string) => {
    return `https://${tenant}.http.msging.net`;
  };

  const EncodeBase64 = (shorName: string, accessKey: string) => {
    return `Key ${btoa(`${shorName}:${accessKey}`)}`;
  };

  const Decodebase64 = (accessKey: string) => {
    return atob(accessKey);
  };

  const trackAndRedirectToPackManager = () => {
    tracking(SO_CONCLUSION_LINK_REDIRECT);
    window.open(getPackManagerUrl(), '_self');
  };

  const getPackManagerUrl = useCallback(() => {
    const params = `packId=${pack.id}`;
    return `${packManagerEnvUrl}?${params}`;
  }, [pack.id, packManagerEnvUrl]);

  useEffect(() => {
    deleteSession();
    tracking(SO_FINISHED);
    setActiveStep(Step.Conclusion);
  }, [deleteSession, setActiveStep, tracking, getPackManagerUrl]);

  return (
    <>
      <PageContainer>
        <LeftColumn>
          <bds-typo variant="fs-32" bold="bold" tag="h2">
            Tudo pronto! 🎉
          </bds-typo>
          <bds-typo variant="fs-20">
            Agora chegou a hora de <b>conhecer o {pack.name ? pack.name : 'seu pack'}!</b>
          </bds-typo>
          <br />
          <bds-typo>Em alguns instantes você será direcionado para a área de ferramentas...</bds-typo>
        </LeftColumn>
        <Center>
          <img src={Rocket} alt="Blip Rocket" />
        </Center>
      </PageContainer>
    </>
  );
};

export default Conclusion;
