import axios from 'axios';
import Spinner from '../components/Spinner';
import { Step } from '../enums';
import { PhoneNumber } from '../interfaces/Waba';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { ApiService } from '../services/Api';
import { Configuration } from '../typings/Configuration';
import { Profile } from '../typings/OIDCProfile';
import { WhatsAppInfo } from '../typings/WhatsAppInfo';

type AppContextData = {
  profile: Profile | undefined;
  settings: Configuration;
  apiService: ApiService;
  facebookAppId: string;
  activeStep: Step;
  setActiveStep: React.Dispatch<React.SetStateAction<Step>>;
  skipActivation: boolean | undefined;
  setSkipActivation: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  whatsAppInfo: WhatsAppInfo;
  setWhatsAppInfo: React.Dispatch<React.SetStateAction<WhatsAppInfo>>;
};

interface AppContextValue {
  userdata: {
    access_token: string | undefined;
    profile: Profile | undefined;
  };
}

export const AppContext = createContext<AppContextData>({} as AppContextData);

export const AppProvider: React.FC<AppContextValue> = ({ children, userdata }) => {
  const [activeStep, setActiveStep] = useState<number>(Step.Preparation);
  const [settings, setSettings] = useState<Configuration>({
    BlipFoodsIdHmg: 402,
    FacebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID as string,
    ApiServer: process.env.REACT_APP_API_SERVER as string,
    ApiPort: process.env.REACT_APP_API_PORT as string,
    BlipFoodsId: 0,
    FacebookBusinessSystemUserToken: process.env.REACT_APP_FACEBOOK_BUSINESS_SYSTEM_USER_TOKEN as string,
    Environment: process.env.REACT_APP_ENV as 'staging' | 'production',
    NumberDaysToExpireUserSession: process.env.REACT_APP_NUMBER_DAYS_TO_EXPIRE_USER_SESSION as any,
    HubspotApiKey: process.env.REACT_APP_HUBSPOT_API_KEY as string,
  });

  const [skipActivation, setSkipActivation] = useState<boolean | undefined>();
  const [whatsAppInfo, setWhatsAppInfo] = useState<WhatsAppInfo>({} as WhatsAppInfo);
  const [initialized, setInitialized] = useState(false);

  const apiService = useMemo(() => {
    if (Object.keys(settings).length > 0) {
      return new ApiService(settings.ApiServer, settings.ApiPort, userdata.access_token);
    }
  }, [settings, userdata]);

  useEffect(() => {
    const urlParams = new URLSearchParams();
    new URLSearchParams(window.location.search).forEach((value, key) => {
      urlParams.append(key.toLowerCase(), decodeURI(value));
    });

    const skipActivationUrl = urlParams.get('skipactivation');

    if (!!skipActivationUrl) {
      setSkipActivation(skipActivationUrl == 'true');
    }

    setWhatsAppInfo({
      numberSituation: 'Vazio',
      inputToken: '',
      phoneNumber: {} as PhoneNumber,
      applicationName: '',
    });
    setInitialized(true);
  }, []);

  return apiService && initialized ? (
    <>
      <AppContext.Provider
        value={{
          settings,
          apiService,
          profile: userdata.profile,
          facebookAppId: settings.FacebookAppId,
          activeStep,
          setActiveStep,
          skipActivation,
          setSkipActivation,
          whatsAppInfo,
          setWhatsAppInfo,
        }}
      >
        {children}
      </AppContext.Provider>
    </>
  ) : (
    <Spinner />
  );
};

export function useAppContext(): AppContextData {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error('use app context must be used within an AppProvider');
  }

  return context;
}
