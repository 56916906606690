import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import { Step } from '../../enums';
import { usePack } from '../../hooks/usePack';
import React from 'react';

const Stepper: React.FC = () => {
  const { activeStep, settings } = useAppContext();

  const { pack } = usePackContext();
  const { getIsSkipChannelActivation } = usePack();
  const canRender = pack.tenant.id != '';

  const getPackConclusionPageNaming = () => {
    const { BlipFoodsId } = settings;
    return pack.id === BlipFoodsId.toString() ? 'Incluir produtos 🎉' : 'Teste seu Pack';
  };

  return canRender ? (
    <bds-stepper>
      <bds-step active={activeStep === Step.Preparation} completed={Step.Preparation < activeStep}>
        Preparação
      </bds-step>
      {!getIsSkipChannelActivation() && (
        <bds-step active={activeStep === Step.WhatsAppActivation} completed={Step.WhatsAppActivation < activeStep}>
          Ativação no WhatsApp
        </bds-step>
      )}
      <bds-step active={activeStep === Step.Conclusion} completed={Step.Conclusion < activeStep}>
        {getPackConclusionPageNaming()}
      </bds-step>
    </bds-stepper>
  ) : (
    <></>
  );
};

export default Stepper;
