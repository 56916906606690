import Robot404 from '../../assets/Robot404.svg';
import { ROUTE_ONBOARDING } from '../../constants/Routes';
import { SO_OPENED } from '../../constants/TrackingEvents';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import React, { FC, useEffect, useState } from 'react';
import { Container, InputArea } from './style';

const PackNotFound: FC = () => {
  const [packId, setPackId] = useState('');
  const { tracking } = useTrackNoEvent();

  const navigateToStart = () => {
    location.href = `${ROUTE_ONBOARDING}?packId=${packId}`;
  };

  const onInput = (ev: CustomEvent) => setPackId(ev.detail.target.value);

  useEffect(() => {
    const inputListner = document.querySelector('bds-input');
    inputListner?.addEventListener('bdsInput', event => onInput(event as CustomEvent));

    return () => inputListner?.removeEventListener('bdsInput', event => onInput(event as CustomEvent));
  }, []);

  useEffect(() => tracking(SO_OPENED), []);

  return (
    <Container>
      <img src={Robot404} alt="404 error" />
      <bds-typo variant="fs-32" bold="bold">
        Pack não encontrado :(
      </bds-typo>
      <bds-typo>
        Acho que meus circuitos entraram em pane tentando achar este Pack. Mas sem problemas, eu tenho muitos ainda.{' '}
      </bds-typo>
      <InputArea>
        <bds-typo>Por favor, insira o número do Pack desejado: </bds-typo>
        <bds-input placeholder="Nº do Pack" />
      </InputArea>
      <bds-button variant="ghost" disabled={packId.length === 0} onClick={navigateToStart}>
        Tentar novamente
      </bds-button>
    </Container>
  );
};

export default PackNotFound;
