export enum Step {
  Preparation = 1,
  WhatsAppActivation = 2,
  Conclusion = 3,
}

export enum Plan {
  Standard = 'standard',
  Enterprise = 'enterprise',
  Lite = 'lite',
}

export enum PackApprovalStatus {
  Pending,
  Approved,
  Disapproved,
}
