import Blipsales from '../../../assets/Blipsales.svg';
import PaperCards from '../../../components/PaperCards';
import { SO_START_WHATSAPP_ACTIVATION, SO_START_WHATSAPP_ACTIVATION_BUTTON } from '../../../constants/TrackingEvents';
import { useAppContext } from '../../../contexts/AppContext';
import { Step } from '../../../enums';
import { useSession } from '../../../hooks/useSession';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import React, { useEffect, useState } from 'react';
import { Center, PageContainer } from '../../../styles';
import { ContainerActions, TitleContainer } from './styles';

type Titles = {
  title: string;
  subTitle: string;
};

const stepTitles: Titles[] = [
  {
    title: 'Vamos ativar seu número no WhatsApp?',
    subTitle: 'Para começar, precisamos verificar se você tem tudo o que precisa para o sucesso da ativação.',
  },
  { title: 'Qual é a situação do número que você vai usar no WhatsApp?', subTitle: 'Pergunta 1 de 4' },
  { title: 'O número a ser ativado começa com 4004 ou 0800?', subTitle: 'Pergunta 2 de 4' },
  { title: 'O número a ser ativado está usando atendimento eletrônico por voz?', subTitle: 'Pergunta 3 de 4' },
  { title: 'O site da sua empresa está no ar e ativo?', subTitle: 'Pergunta 4 de 4' },
];

const CheckList: React.FC = () => {
  const { setActiveStep } = useAppContext();
  const { tracking } = useTrackNoEvent();
  const [step, setStep] = useState(0);
  const advanceStep = () => setStep(step => step + 1);
  const retreatStep = () => setStep(step => step - 1);
  const { saveSession } = useSession();

  const trackAndAdvance = () => {
    tracking(SO_START_WHATSAPP_ACTIVATION_BUTTON, { buttonName: 'Próximo', screenName: 'Início checklist' });
    advanceStep();
  };

  const stepComponents = () => {
    if (step == 0) {
      return (
        <>
          <bds-typo tag="h4" variant="fs-32" bold="bold">
            {stepTitles[step].title}
          </bds-typo>
          <bds-typo tag="h4" variant="fs-14">
            {stepTitles[step].subTitle}
          </bds-typo>
        </>
      );
    }
    return (
      <>
        <TitleContainer>
          <bds-typo line-height="plus" tag="h4" variant="fs-20" bold="bold">
            {stepTitles[step].title}
            {step == 3 && (
              <bds-tooltip position="right-bottom" tooltip-text="Um atendimento automático, como URA ou PABX.">
                <bds-icon size="small" theme="solid" name="info" />
              </bds-tooltip>
            )}
          </bds-typo>
          <bds-typo tag="h4" variant="fs-12">
            {stepTitles[step].subTitle}
          </bds-typo>
        </TitleContainer>
      </>
    );
  };

  useEffect(() => {
    setActiveStep(Step.WhatsAppActivation);
    tracking(SO_START_WHATSAPP_ACTIVATION);
    saveSession();
  }, []);

  return (
    <>
      <PageContainer>
        <div>
          {stepComponents()}
          {step > 0 && <PaperCards step={step} advanceStep={advanceStep} retreatStep={retreatStep} />}
          <ContainerActions>
            {step == 0 && (
              <bds-button arrow={true} onClick={trackAndAdvance}>
                Próximo
              </bds-button>
            )}
          </ContainerActions>
        </div>
        <div>
          <Center>
            <img src={Blipsales} alt="Blip sales" />
          </Center>
        </div>
      </PageContainer>
    </>
  );
};

export default CheckList;
