export const ROUTE_ONBOARDING = '/onboarding';
export const ROUTE_PACK_NOT_FOUND = `${ROUTE_ONBOARDING}/pack-not-found`;
export const ROUTE_INSTALLATION = `${ROUTE_ONBOARDING}/installation`;
export const ROUTE_PLUGIN_INTEGRATION = `${ROUTE_ONBOARDING}/plugin-integration`;
export const ROUTE_START_ACTIVATION = `${ROUTE_ONBOARDING}/start-activation`;
export const ROUTE_WHATSAPP_BUSINESS = `${ROUTE_ONBOARDING}/whatsapp-business`;
export const ROUTE_EMBEDDED_SIGNUP = `${ROUTE_ONBOARDING}/embedded-signup`;
export const ROUTE_NUMBER_ACTIVATION = `${ROUTE_ONBOARDING}/number-activation`;
export const ROUTE_ACTIVATION = `${ROUTE_ONBOARDING}/activation`;
export const ROUTE_ACTIVATION_FAILED = `${ROUTE_ONBOARDING}/activation-failed`;
export const ROUTE_WHATSAPP_CONFIGURATION = `${ROUTE_ONBOARDING}/configuration`;
export const ROUTE_CONCLUSION = `${ROUTE_ONBOARDING}/conclusion`;