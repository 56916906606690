import Spinner from '../components/Spinner';
import { usePack } from '../hooks/usePack';
import { useSession } from '../hooks/useSession';
import React, { createContext, FC, useEffect, useState } from 'react';
import { Pack } from '../typings/Pack';
import { useAppContext } from './AppContext';

type PackContextData = {
  pack: Pack;
  setPack: React.Dispatch<React.SetStateAction<Pack>>;
};

export const PackContext = createContext<PackContextData>({} as PackContextData);

export const PackProvider: FC = ({ children }) => {
  const { settings } = useAppContext();
  const [pack, setPack] = useState<Pack>({ id: '', installed: false, tenant: { id: '' } });
  const { fetchPack } = usePack();
  const { deleteSession } = useSession();
  const [initialized, setInitialized] = useState(false);
  const isHomologation = settings.Environment == 'staging';

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search.toLowerCase());
      const packUrl = process.env.REACT_APP_PACK_ID;
      const tenantUrl = process.env.REACT_APP_TENANT;
      const routerShortNameUrl = urlParams.get('botshortname');

      if (packUrl) {
        const fetchedPack = await fetchPack(packUrl);
        if (fetchedPack) {
          fetchedPack.tenant = { id: tenantUrl ?? '' };
          fetchedPack.routerShortName = isHomologation && routerShortNameUrl ? routerShortNameUrl : '';
          setPack(fetchedPack);
        } else {
          deleteSession();
        }
      }
      setInitialized(true);
    })();
  }, []);

  useEffect(() => {
    if (pack.id && pack.tenant.id) {
      // These console.log are to facilitate the traceability of the information, due to the process being transparent to the user.
      console.log(`%c PackId: ${pack.id}`, 'background: #222; color: #bada55; font-size: medium');
      console.log(`%c Tenant: ${pack.tenant.id}`, 'background: #222; color: #bada55; font-size: medium');
    }
  }, [pack.id, pack.tenant.id]);

  return initialized ? (
    <>
      <PackContext.Provider value={{ pack, setPack }}>{children}</PackContext.Provider>
    </>
  ) : (
    <Spinner />
  );
};

export function usePackContext(): PackContextData {
  const context = React.useContext(PackContext);

  if (!context) {
    throw new Error('use app context must be used within an AppProvider');
  }

  return context;
}
