import { App } from 'Self-onboading/App';
import MainContainer from 'Self-onboading/pages/MainContainer/MainContainer';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { useHashPrevention } from 'hooks/useHashPrevention';
import { Profile } from 'oidc-client';
import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { InjectMicrofrontEnd } from 'services/MicrofrontEnd';
import { BaseUserData } from 'typings/BaseUserData';

interface OnboardingUserData {
  access_token: string | undefined;
  profile: Profile | undefined;
}

const SelfOnboarding: React.FC = () => {
  const [userData, setUserData] = useState<OnboardingUserData>({
    access_token: undefined,
    profile: undefined,
  });
  const auth = useAuth();
  useHashPrevention('/onboarding');

  useEffect(() => {
    document.title = 'Blip Packs - Onboarding';

    setUserData({
      access_token: auth.userData?.access_token,
      profile: auth.userData?.profile,
    });
  }, [auth.userData]);

  return (
    <>
      {userData?.access_token ? (
        <>
          <Header />
          <App userdata={userData}></App>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SelfOnboarding;
