import { defineCustomElements } from 'blip-ds/loader';
import { AppProvider } from './contexts/AppContext';
import { PackProvider } from './contexts/PackContext';
import React, { StrictMode, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Profile } from './typings/OIDCProfile';
import { GlobalStyles } from './styles';
import MainContainer from './pages/MainContainer/MainContainer';

interface AppProps {
  userdata: {
    access_token: string | undefined;
    profile: Profile | undefined;
  };
}

export const App: React.FC<AppProps> = ({ userdata }) => {
  defineCustomElements(window);

  return (
    <>
      <GlobalStyles />
      <AppProvider userdata={userdata}>
        {/* <BrowserRouter> */}
        <PackProvider>
          <MainContainer />
        </PackProvider>
        {/* </BrowserRouter> */}
      </AppProvider>
    </>
  );
};
