import { useAppContext } from '../contexts/AppContext';
import { usePackContext } from '../contexts/PackContext';
import { useCallback } from 'react';
import { getDeviceType } from '../utils';
import { track } from 'services/Segment';

export type TrackProps = {
  screenName?: string;
  buttonName?: string;
  linkName?: string;
  userOption?: string;
  statusButton?: string;
  status?: string;
  lastPageUrl?: string;
  currentPageUrl?: string;
  error?: string;
  questionAnswer?: string;
  packId?: string;
  tenant?: string;
  plan?: string;
  routerShortName?: string;
  merchantName?: string;
  installationId?: string;
  action?: string;
};

export const useTrackNoEvent = () => {
  const { skipActivation, whatsAppInfo } = useAppContext();
  const { pack } = usePackContext();

  const createPayload = useCallback(
    (trackProps?: TrackProps) => {
      const packId = trackProps?.packId ?? pack?.id;
      const tenantId = trackProps?.tenant ?? pack?.tenant?.id;
      const planName = trackProps?.plan ?? pack?.tenant?.plan?.name;
      const routerShortName = trackProps?.routerShortName ?? pack?.routerShortName;
      const merchantName = trackProps?.merchantName ?? pack.companyName;
      const installationId = trackProps?.installationId ?? pack.installationId;

      const payload = {
        merchantName: merchantName ?? '',
        packId: packId ?? '',
        tenant: tenantId,
        plan: planName ?? '',
        botIdentifier: routerShortName ?? '',
        activationOption: whatsAppInfo.numberSituation,
        deviceType: getDeviceType(),
        operationalSystem: navigator.userAgentData?.platform ?? '',
        screenResolution: `${screen.width}x${screen.height}`,
        installationId: installationId ?? '',
        skipActivation: skipActivation,
        ownerSO: 'yankee',
      };

      return { ...trackProps, ...payload };
    },
    [
      pack?.companyName,
      pack?.id,
      pack?.installationId,
      pack?.routerShortName,
      pack?.tenant?.id,
      pack?.tenant?.plan?.name,
      skipActivation,
      whatsAppInfo.numberSituation,
    ],
  );

  const tracking = useCallback(
    (event: string, trackProps?: TrackProps) => {
      const payload = createPayload(trackProps);
      track(event, payload);
    },
    [createPayload],
  );

  return { tracking };
};
