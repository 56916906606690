import { useHashPrevention } from 'hooks/useHashPrevention';
import { useAuth } from 'oidc-react';
import { FC, useEffect } from 'react';
import { InjectMicrofrontEnd } from 'services/MicrofrontEnd';

const PackManager: FC = () => {
  const auth = useAuth();
  useHashPrevention('/pack-manager');

  useEffect(() => {
    document.title = 'Gestão de Packs';
    const urlScript = `${process.env.REACT_APP_SRC_PACK_MANAGER}main.js`;
    const urlSettings = `${process.env.REACT_APP_SRC_PACK_MANAGER}settings.json`;
    const tagMfe = 'bpacks-mfe-pack-manager-fragment';

    const userData = {
      access_token: auth.userData?.access_token,
      profile: auth.userData?.profile,
      settingsSource: urlSettings,
    };

    InjectMicrofrontEnd('container-mfe', urlScript, tagMfe, userData);
  }, [auth.userData]);

  return <div id="container-mfe" />;
};

export default PackManager;
