import styled from 'styled-components';

export const ContainerActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  div:last-child {
    margin-top: 190px;
  }
`;

export const BottomInfo = styled.div`
  margin-top: 100px;
`;
