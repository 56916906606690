import { ROUTE_WHATSAPP_BUSINESS } from '../../constants/Routes';
import {
  SO_CHECKLIST_Q1,
  SO_CHECKLIST_Q2,
  SO_CHECKLIST_Q3,
  SO_CHECKLIST_Q4,
  SO_HELP_ACTIVATION,
  SO_LINK,
} from '../../constants/TrackingEvents';
import { useAppContext } from '../../contexts/AppContext';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionButtonsContainer, CardsContainer, TipsContainer } from './style';

type CardSelection = 'rightPaper' | 'leftPaper';

type Tips = {
  [key in CardSelection]?: JSX.Element;
};

type PaperCardProps = {
  step: number;
  advanceStep: () => void;
  retreatStep: () => void;
};

type ButtonTitles = {
  leftPaper?: {
    primaryButtonText: string;
    secondaryButtonText?: string;
  };
  rightPaper?: {
    primaryButtonText: string;
    secondaryButtonText?: string;
  };
};

const PaperCards: FC<PaperCardProps> = ({ step, advanceStep }) => {
  const leftPaperRef = useRef<HTMLBdsRadioElement>(null);
  const rightPaperRef = useRef<HTMLBdsRadioElement>(null);
  const [cardTexts, setCardTexts] = useState(['Novo', 'Em uso']);
  const [optionSelected, setOptionSelected] = useState<CardSelection>();
  const navigate = useNavigate();
  const { setWhatsAppInfo } = useAppContext();
  const { tracking } = useTrackNoEvent();

  const handleDeleteWhatsAppHelpTrack = () => {
    tracking(SO_LINK, {
      screenName: 'Início checklist',
      linkName: 'Veja como realizar o procedimento',
    });
  };

  const newNumberTip = (
    <bds-typo variant="fs-12">
      <b>Legal!</b> No caso de um chip nunca usado, coloque-o em um celular e verifique se ele recebe mensagens ou
      ligações.
    </bds-typo>
  );

  const numberInUseTip = (
    <>
      <bds-typo variant="fs-12">
        <b>Atenção!</b> Para seguir com essa opção, você precisa primeiro apagar sua conta do WhatsApp. Essa ação também
        removerá seu histórico de conversas.&nbsp;
        <a
          href="https://faq.whatsapp.com/605464643328528/"
          onClick={handleDeleteWhatsAppHelpTrack}
          target="_blank"
          rel="noreferrer"
        >
          Veja como realizar o procedimento.
        </a>
      </bds-typo>
      <br />
      <bds-typo variant="fs-12">
        Se preferir fazer uma ativação guiada sem perder o histórico das conversas, entre em contato conosco e agende um
        horário com nossa equipe.
      </bds-typo>
    </>
  );

  const numberFormatFromPabxTip = (
    <bds-typo variant="fs-12">
      <b>Ops!</b> Neste caso, você precisa usar outro número para ativar. Os números com esses prefixos não conseguem
      receber o código enviado por SMS ou a chamada telefônica que o Facebook faz para validar a ativação.
    </bds-typo>
  );

  const numberFromBotVoiceAttendanceTip = (
    <bds-typo variant="fs-12">
      <b>Ops!</b> Nesse caso, você precisa solicitar que o serviço de atendimento eletrônico por voz seja{' '}
      <b>desativado temporariamente</b>. Isso é necessário para você poder receber o código SMS ou a chamada telefônica
      que o Facebook faz para validar a ativação.
    </bds-typo>
  );

  const noActiveWebsiteTip = (
    <bds-typo variant="fs-12">
      <b>Ops!</b> Para a validação da sua nova conta, seu negócio deve ter um site publicado e ativo. O WhatsApp avalia
      uma prova pública da existência da empresa para a aprovação.
    </bds-typo>
  );

  const tipsByStep: Tips[] = [
    {}, // No tips for step 0
    { leftPaper: newNumberTip, rightPaper: numberInUseTip },
    { leftPaper: numberFormatFromPabxTip },
    { leftPaper: numberFromBotVoiceAttendanceTip },
    { rightPaper: noActiveWebsiteTip },
  ];

  const actionButtons: ButtonTitles[] = [
    {},
    {
      leftPaper: {
        primaryButtonText: 'Próximo',
      },
      rightPaper: {
        primaryButtonText: 'Ok, já apaguei minha conta',
        secondaryButtonText: 'Entre em contato',
      },
    },
    {
      leftPaper: {
        primaryButtonText: 'Ok, ativarei outro número',
      },
      rightPaper: {
        primaryButtonText: 'Próximo',
      },
    },
    {
      leftPaper: {
        primaryButtonText: 'Ok, já desativei',
      },
      rightPaper: {
        primaryButtonText: 'Próximo',
      },
    },
    {
      leftPaper: {
        primaryButtonText: 'Próximo',
      },
      rightPaper: {
        primaryButtonText: 'Ok, já coloquei o site no ar',
      },
    },
  ];

  const showTips = optionSelected && tipsByStep[step][optionSelected] && (
    <TipsContainer>{tipsByStep[step][optionSelected]}</TipsContainer>
  );

  const trackAndAdvanceQuestion = async () => {
    if (optionSelected) {
      tracking(SO_CHECKLIST_Q1, {
        buttonName: actionButtons[step][optionSelected]?.primaryButtonText,
        questionAnswer: optionSelected == 'leftPaper' ? 'Novo' : 'Em uso',
      });
      advanceStep();
    }
  };

  const showButtons = () => {
    if (optionSelected && actionButtons[step][optionSelected]) {
      switch (step) {
        case 1:
          return (
            <>
              {actionButtons[step][optionSelected]?.secondaryButtonText && (
                <bds-button
                  onClick={() => {
                    tracking(SO_HELP_ACTIVATION);
                    window.open(
                      'https://api.whatsapp.com/send/?phone=5511998322319&text&type=phone_number&app_absent=0',
                      '_blank',
                    );
                  }}
                  variant="tertiary"
                >
                  {actionButtons[step][optionSelected]?.secondaryButtonText}
                </bds-button>
              )}
              <bds-button onClick={trackAndAdvanceQuestion} arrow>
                {actionButtons[step][optionSelected]?.primaryButtonText}
              </bds-button>
            </>
          );
        case 2:
          return (
            <>
              <bds-button
                onClick={() => {
                  tracking(SO_CHECKLIST_Q2, {
                    buttonName: actionButtons[step][optionSelected]?.primaryButtonText,
                    questionAnswer: optionSelected == 'leftPaper' ? 'Sim' : 'Não',
                  });
                  advanceStep();
                }}
                arrow
              >
                {actionButtons[step][optionSelected]?.primaryButtonText}
              </bds-button>
            </>
          );

        case 3:
          return (
            <>
              <bds-button
                onClick={() => {
                  tracking(SO_CHECKLIST_Q3, {
                    buttonName: actionButtons[step][optionSelected]?.primaryButtonText,
                    questionAnswer: optionSelected == 'leftPaper' ? 'Sim' : 'Não',
                  });
                  advanceStep();
                }}
                arrow
              >
                {actionButtons[step][optionSelected]?.primaryButtonText}
              </bds-button>
            </>
          );

        case 4:
          return (
            <>
              <bds-button
                onClick={() => {
                  tracking(SO_CHECKLIST_Q4, {
                    buttonName: actionButtons[step][optionSelected]?.primaryButtonText,
                    questionAnswer: optionSelected == 'leftPaper' ? 'Sim' : 'Não',
                  });
                  navigate(ROUTE_WHATSAPP_BUSINESS);
                }}
                arrow
              >
                {actionButtons[step][optionSelected]?.primaryButtonText}
              </bds-button>
            </>
          );
      }
    }
  };

  const handleOptionSelected = useCallback(
    (option: CardSelection | null) => {
      if (leftPaperRef.current && rightPaperRef.current) {
        if (option) {
          setOptionSelected(option);
          if (option == 'rightPaper') {
            rightPaperRef.current.checked = true;
            leftPaperRef.current.checked = false;
          } else {
            leftPaperRef.current.checked = true;
            rightPaperRef.current.checked = false;
          }
        } else {
          setOptionSelected(undefined);
          rightPaperRef.current.checked = false;
          leftPaperRef.current.checked = false;
        }

        if (step == 1) {
          const newNumberSituation = option == 'leftPaper' ? 'Novo' : 'Em uso';
          setWhatsAppInfo(whatsAppInfo => ({ ...whatsAppInfo, numberSituation: newNumberSituation }));
        }
      }
    },
    [setWhatsAppInfo, step],
  );

  useEffect(() => {
    step != 1 && setCardTexts(['Sim', 'Não']);
    handleOptionSelected(null);
  }, [step, handleOptionSelected]);

  useEffect(() => {
    const radioListener = document.querySelector('bds-radio-group');

    radioListener?.addEventListener('bdsClickChange', (event: any) => {
      const radioValue = event.target.value;
      handleOptionSelected(radioValue);
    });

    return () =>
      radioListener?.removeEventListener('bdsClickChange', (event: any) => {
        const radioValue = event.target.value;
        handleOptionSelected(radioValue);
      });
  }, [handleOptionSelected]);

  return (
    <CardsContainer>
      <bds-radio-group>
        <bds-paper onClick={() => handleOptionSelected('leftPaper')}>
          <bds-radio ref={leftPaperRef} value="leftPaper" />
          {step == 1 ? <bds-icon size="xxx-large" name="chip" /> : <bds-icon size="xxx-large" name="checkball" />}
          <div>
            <bds-typo variant="fs-20" tag="h4" bold="bold" margin={false}>
              {cardTexts[0]}
            </bds-typo>
            {step == 1 && <bds-typo variant="fs-12">Um número que nunca foi usado no WhatsApp</bds-typo>}
          </div>
        </bds-paper>
        <bds-paper onClick={() => handleOptionSelected('rightPaper')}>
          <bds-radio ref={rightPaperRef} value="rightPaper" />
          {step == 1 ? <bds-icon size="xxx-large" name="sms" /> : <bds-icon size="xxx-large" name="error" />}
          <div>
            <bds-typo variant="fs-20" tag="h4" bold="bold" margin={false}>
              {cardTexts[1]}
            </bds-typo>
            {step == 1 && <bds-typo variant="fs-12">Um número que já foi usado ou está em uso no WhatsApp</bds-typo>}
          </div>
        </bds-paper>
      </bds-radio-group>
      {showTips}
      <ActionButtonsContainer>{showButtons()}</ActionButtonsContainer>
    </CardsContainer>
  );
};

export default PaperCards;
