import { BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import styled from 'styled-components';

export const ContainerActions = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 24px;
`;

export const ContainerInputLabel = styled.div`
  display: flex;
  align-items: center;

  bds-typo {
    margin-bottom: 4px;
  }

  bds-tooltip {
    height: 18px;
    margin-left: 4px;
  }
`;

export const FullWidthContainer = styled.div`
    margin-top: 8px;
    display: flex;
    width: 100%;
`;

export const CenteredContainer = styled.div`
    display: flex;
    align-items: center;
`;
