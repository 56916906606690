import ContainerSuccess from '../../../assets/ContainerSuccess.svg';
import GirlIpad from '../../../assets/GirlIpad.svg';
import { ACTIVATE_WHATSAPP_ROUTE } from '../../../constants/ApiRoutes';
import ExternalUrls from '../../../constants/ExternalUrls';
import { ROUTE_ACTIVATION_FAILED, ROUTE_WHATSAPP_CONFIGURATION } from '../../../constants/Routes';
import {
  SO_HUBSPOT_TICKET_CREATION,
  SO_WAITING_WPP_ACTIVATION,
  SO_WHATSAPP_ACTIVATED,
  SO_WHATSAPP_ACTIVATED_NEXT,
  SO_WHATSAPP_ACTIVATION_ERROR,
} from '../../../constants/TrackingEvents';
import { useAppContext } from '../../../contexts/AppContext';
import { usePackContext } from '../../../contexts/PackContext';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createToastError } from '../../../services/Toast';
import { Center, ContainerProgressBar, PageContainer } from '../../../styles';
import { PackNames } from '../../../typings/Pack';
import { ContainerModal } from './styles';

type HubspotApiReqBody = {
  subject: string;
  id_contrato_blip: string;
  packs_categoria: PackNames;
  hs_ticket_grupo: string;
  hubspot_owner_id: string;
  idioma: 'Inglês' | 'Espanhol' | 'Português';
  tr_canal_contratado: 'WhatsApp';
  tr_tipo_de_contrato: 'BLiP Enterprise';
  contact_email: string;
  id_do_chatbot: string;
};

/* Fixed Hubspot user IDs for ticket owners */
const hubspotOwnerIds = {
  prd: '45136728',
  hmg: '366732419',
};

const Activation: React.FC = () => {
  const { apiService, whatsAppInfo, settings, profile } = useAppContext();
  const { pack } = usePackContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const locationState = useLocation().state as { pin: string } | undefined;
  const { tracking } = useTrackNoEvent();

  const getPacksCategory = () => {
    const packIdTable: { [key: string]: PackNames } = {
      [settings.BlipFoodsId]: 'Foods',
    };
    return packIdTable[pack.id] ? packIdTable[pack.id] : 'Outros';
  };

  const createHubspotTicket = async () => {
    if (getPacksCategory() == 'Outros') {
      return;
    }

    const url = settings.Environment == 'staging' ? ExternalUrls.hubspotTicketApiHmg : ExternalUrls.hubspotTicketApiPrd;
    const ownerId = settings.Environment == 'staging' ? hubspotOwnerIds.hmg : hubspotOwnerIds.prd;

    try {
      await fetch(url, {
        headers: {
          AuthorizationKey: settings.HubspotApiKey,
          'Content-type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          subject: `[Packs][${pack.name}] ${pack.companyName}`,
          id_contrato_blip: pack.tenant.id,
          packs_categoria: getPacksCategory(),
          hs_ticket_grupo: 'CANAIS - Whatsapp - Onboarding - ativação inicial',
          hubspot_owner_id: ownerId,
          idioma: 'Português',
          tr_canal_contratado: 'WhatsApp',
          tr_tipo_de_contrato: 'BLiP Enterprise',
          contact_email: profile?.Email,
          id_do_chatbot: pack.routerShortName,
        } as HubspotApiReqBody),
        method: 'POST',
      });
      tracking(SO_HUBSPOT_TICKET_CREATION, { status: 'Sucesso' });
    } catch (error) {
      tracking(SO_HUBSPOT_TICKET_CREATION, { status: 'Falha', error: JSON.stringify({ error }) });
      console.error('Hubspot error: ', { error });
      createToastError('Erro ao ativar o número', 'Confira dados do ticket');
      navigate(ROUTE_ACTIVATION_FAILED);
    }
  };

  const activateNumber = async () => {
    await createHubspotTicket();

    try {
      await apiService.post(ACTIVATE_WHATSAPP_ROUTE, {
        shortName: pack.routerShortName,
        phoneNumberId: whatsAppInfo.phoneNumber.id,
        wabaId: whatsAppInfo.phoneNumber.wabaId,
        pin: locationState?.pin,
      });
      setIsModalOpen(true);
      tracking(SO_WHATSAPP_ACTIVATED);
    } catch (error: any) {
      if (error.response?.data?.Error?.ErrorCode === 76 || error.response?.data?.Error?.ErrorCode === 77) {
        tracking(SO_WHATSAPP_ACTIVATION_ERROR, { error: 'Hubspot' });
      } else {
        tracking(SO_WHATSAPP_ACTIVATION_ERROR, { error: 'API' });
      }
      navigate(ROUTE_ACTIVATION_FAILED);
    }
  };

  const handleNavigation = () => {
    tracking(SO_WHATSAPP_ACTIVATED_NEXT);
    navigate(ROUTE_WHATSAPP_CONFIGURATION);
  };

  useEffect(() => {
    tracking(SO_WAITING_WPP_ACTIVATION);
    activateNumber();
  }, []);

  return (
    <>
      <PageContainer>
        <div>
          <bds-typo variant="fs-32" bold="bold" tag="h2">
            Estamos preparando tudo para você
          </bds-typo>
          <bds-typo>Tudo estará pronto dentro de instantes.</bds-typo>
          <ContainerProgressBar>
            <bds-loading-bar percent={100} />
            <bds-typo tag="span" variant="fs-14">
              Ativando o número e vinculando ao seu Pack...
            </bds-typo>
          </ContainerProgressBar>
        </div>
        <Center>
          <img src={GirlIpad} alt="Blip Pack" />
        </Center>
      </PageContainer>

      <bds-modal open={isModalOpen} id="successModal" close-button="false">
        <ContainerModal>
          <img src={ContainerSuccess} alt="Blip Pack" />
          <bds-typo variant="fs-32" bold="bold" tag="h4">
            Ativação concluída com sucesso!
          </bds-typo>
        </ContainerModal>
        <bds-modal-action>
          <bds-button onClick={handleNavigation} arrow variant="primary">
            Continuar
          </bds-button>
        </bds-modal-action>
      </bds-modal>
    </>
  );
};

export default Activation;
