import { ModalAction } from '../../components/TenantModal/styles';
import { BlipPacksLogout } from '../../constants/BlipPacksContainerEvents';
import { useSession } from '../../hooks/useSession';
import React from 'react';
import { PublishEvent } from '../../services/EventPublisher';
import { FlexColumn } from '../../styles';
import { ModalContent } from './styles';

type ConfirmAccountModalProps = {
  isOpen: boolean;
};

const ConfirmAccountModal: React.FC<ConfirmAccountModalProps> = ({ isOpen }) => {
  const { deleteSession } = useSession();

  const handleLogout = () => {
    deleteSession();
    PublishEvent(BlipPacksLogout, {});
  };

  return (
    <bds-modal open={isOpen} close-button="false" size="dynamic">
      <FlexColumn>
        <bds-typo variant="fs-20" bold="bold">
          Você ainda não ativou sua conta do Blip.
        </bds-typo>
        <ModalContent>
          <bds-typo variant="fs-14">
            Você recebeu um e-mail de ativação quando criou ela. <br />
            Quando estiver tudo certo, logue novamente.
          </bds-typo>
        </ModalContent>
        <ModalAction>
          <bds-button onClick={handleLogout}>Deslogar</bds-button>
        </ModalAction>
      </FlexColumn>
    </bds-modal>
  );
};

export default ConfirmAccountModal;
