import Banner from '../../components/Banner';
import RouteGuard from '../../components/RouteGuard';
import Stepper from '../../components/Stepper';
import {
  ROUTE_ACTIVATION,
  ROUTE_ACTIVATION_FAILED,
  ROUTE_CONCLUSION,
  ROUTE_EMBEDDED_SIGNUP,
  ROUTE_INSTALLATION,
  ROUTE_NUMBER_ACTIVATION,
  ROUTE_ONBOARDING,
  ROUTE_PACK_NOT_FOUND,
  ROUTE_PLUGIN_INTEGRATION,
  ROUTE_START_ACTIVATION,
  ROUTE_WHATSAPP_BUSINESS,
  ROUTE_WHATSAPP_CONFIGURATION,
} from '../../constants/Routes';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import { PackApprovalStatus } from '../../enums';
import { useSession } from '../../hooks/useSession';
import Conclusion from '../../pages/Conclusion';
import Installation from '../../pages/Installation';
import PackNotFound from '../../pages/PackNotFound';
import PluginIntegration from '../../pages/PluginIntegration';
import Welcome from '../../pages/Welcome';
import Activation from '../../pages/WhatsApp/Activation';
import ActivationFailed from '../../pages/WhatsApp/ActivationFailed';
import CheckList from '../../pages/WhatsApp/CheckList';
import Configuration from '../../pages/WhatsApp/Configuration';
import EmbeddedSignup from '../../pages/WhatsApp/EmbeddedSignup';
import NumberActivation from '../../pages/WhatsApp/NumberActivation';
import WhatsAppBusinessInfo from '../../pages/WhatsApp/WhatsAppBusinessInfo';
import React, { FC, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { InjectHotJarScript } from '../../services/Hotjar';
import { Container, ContentContainer } from './styles';

export const MainContainer: FC = () => {
  const { settings, whatsAppInfo } = useAppContext();
  const { pack } = usePackContext();
  const { setEnableSaveEession, tryRestoreSession } = useSession();
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const [routePath, setRoutePath] = useState('');
  const location = useLocation();

  useEffect(() => {
    setRoutePath(location.pathname);
  }, [location]);

  useEffect(() => {
    if (settings.Environment == 'production') {
      InjectHotJarScript();
    }
  }, []);

  useEffect(() => {
    const hasLatestApprovedVersion = pack.status == PackApprovalStatus.Approved;
    const validPack = !!pack.id && hasLatestApprovedVersion;
    let routeRedirect = validPack ? '' : ROUTE_PACK_NOT_FOUND;

    const restoredSessionData = tryRestoreSession();
    if (restoredSessionData.success) {
      routeRedirect = restoredSessionData.currentRoute;
    }
    setEnableSaveEession(routeRedirect != ROUTE_CONCLUSION);
    setInitialized(true);

    !!routeRedirect && routeRedirect != ROUTE_ONBOARDING && navigate(routeRedirect);
  }, []);

  const RenderSteps = () => {
    if (routePath === ROUTE_ONBOARDING) {
      return <Welcome />;
    } else if (routePath === ROUTE_PACK_NOT_FOUND) {
      return <PackNotFound />;
    } else if (routePath === ROUTE_START_ACTIVATION) {
      return <CheckList />;
    } else if (routePath === ROUTE_INSTALLATION) {
      return (
        <RouteGuard isAllowed={!!pack.companyName}>
          <Installation />
        </RouteGuard>
      );
    } else if (routePath === ROUTE_WHATSAPP_BUSINESS) {
      return (
        <RouteGuard isAllowed={pack.installed}>
          <WhatsAppBusinessInfo />
        </RouteGuard>
      );
    } else if (routePath === ROUTE_EMBEDDED_SIGNUP) {
      return (
        <RouteGuard isAllowed={pack.installed}>
          <EmbeddedSignup />
        </RouteGuard>
      );
    } else if (routePath === ROUTE_NUMBER_ACTIVATION) {
      return (
        <RouteGuard isAllowed={pack.installed && !!whatsAppInfo.inputToken}>
          <NumberActivation />
        </RouteGuard>
      );
    } else if (routePath === ROUTE_ACTIVATION) {
      return (
        <RouteGuard isAllowed={pack.installed}>
          <Activation />
        </RouteGuard>
      );
    } else if (routePath === ROUTE_PLUGIN_INTEGRATION) {
      return (
        <RouteGuard isAllowed={pack.installed && !!pack.plugin?.url}>
          <PluginIntegration />
        </RouteGuard>
      );
    } else if (routePath === ROUTE_WHATSAPP_CONFIGURATION) {
      return (
        <RouteGuard
          isAllowed={
            pack.installed && !!whatsAppInfo.inputToken && !!whatsAppInfo.applicationName && !!whatsAppInfo.phoneNumber
          }
        >
          <Configuration />
        </RouteGuard>
      );
    } else if (routePath === ROUTE_CONCLUSION) {
      return (
        <RouteGuard isAllowed={pack.installed}>
          <Conclusion />
        </RouteGuard>
      );
    } else if (routePath === ROUTE_ACTIVATION_FAILED) {
      return <ActivationFailed />;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Banner />
      <Container>
        <Stepper />
        {initialized && <ContentContainer>{RenderSteps()}</ContentContainer>}
      </Container>
    </>
  );
};

export default MainContainer;
