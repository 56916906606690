export const RESOURCES_ROUTE = 'resources';
export const INSTALLATIONS_ROUTE = 'installations';
export const GET_INSTALLATION_BY_SHORTNAME = '/installations/last';
export const TENANTS_ROUTE = 'tenants';
export const INSTALL_CHATBOT_ROUTE = 'installations';
export const ACTIVATE_WHATSAPP_ROUTE = '/whatsapp/activate-number';
export const PLUGIN_ROUTE = 'configurations/application';
export const APPLICATION_ROUTE = 'packs';
export const BLIP_CHAT_CONFIGURATION = 'configurations/blip-chat';
export const INSTALLATIONS_PROCESS_STATUS = 'statusprocess';
export const VALIDATE_INSTALLATION_EXISTS = 'installations/validate-exists';
