import axios from 'axios';
import credentials from './credentials.json'

interface IGoogleSheetsResponse {
  data: {access_token: string}
}
export const getGoogleAccessToken = async (): Promise<IGoogleSheetsResponse | undefined> => {
  try {
      const resp = await axios.post('https://accounts.google.com/o/oauth2/token', {
          token_uri: credentials.token_uri,
          client_id: credentials.client_id,
          client_secret: credentials.client_secret,
          refresh_token: credentials.refresh_token,
          grant_type: 'refresh_token',
      });
      return resp;
  } catch (e) {
      console.error(e);
  }
};

export const insertLineInSheets = async (authorization: string, data: string[][]) => {
  try {
      const spreadsheetId = credentials.spreadsheet_id;
      const range = credentials.range;
      const resp = await axios.post(
          `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}:append?valueInputOption=RAW`,
          {
              majorDimension: 'ROWS',
              values: data,
          },
          {
              headers: {
                  authorization: `Bearer ${authorization}`,
              },
          }
      );
      return resp;
  } catch (e) {
      console.error(e);
  }
};

const getAccessToken = async () => {
  try {
      const response = await getGoogleAccessToken();
      if (response) {
        const { data }= response;
        const { access_token: accessToken } = data;
        return accessToken;
      }
  } catch (e) {
      console.error(e);
  }
};

export async function saveTermsOfUseSheet(fullName: string, email: string) {
  const acc = await getAccessToken();
  if (acc) {
    await insertLineInSheets(acc, [[fullName, email, new Date().toLocaleDateString('pt-BR')]]);
  }
}

