import { Table } from '../../typings/Table';
import BlipFoods from './BlipFoods.svg';
import BlipFoodsSmall from './BlipFoodsSmall.svg';
import BlipLearn from './BlipLearn.svg';
import BlipLearnSmall from './BlipLearnSmall.svg';
import BlipPacks from './BlipPacks.svg';
import BlipPacksSmall from './BlipPacksSmall.svg';
import BlipShop from './BlipShop.svg';
import BlipShopSmall from './BlipShopSmall.svg';
import BlipGo from './BlipGo.svg';
import BlipGoSmall from './BlipGo.svg';

const Banners: Table = {
  BlipFoods,
  BlipFoodsSmall,
  BlipLearn,
  BlipLearnSmall,
  BlipPacks,
  BlipPacksSmall,
  BlipShop,
  BlipShopSmall,
  BlipGo,
  BlipGoSmall,
};

export default Banners;
