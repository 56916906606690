import { APPLICATION_ROUTE, PLUGIN_ROUTE } from '../constants/ApiRoutes';
import { useAppContext } from '../contexts/AppContext';
import { PluginData } from '../typings/PluginData';
import { useCallback, useMemo } from 'react';
import { NumberTable } from '../typings/NumberTable';
import { Pack } from '../typings/Pack';
import { PackApprovalStatus, Plan } from '../enums';
import { usePackContext } from '../contexts/PackContext';

export type Application = {
  id: string;
  status?: PackApprovalStatus;
  name?: string;
  description?: string;
  channels?: string;
  version?: string;
  routerShortName?: string;
  plugin?: PluginData;
};

export const usePack = () => {
  const { skipActivation, apiService, settings } = useAppContext();
  const { pack } = usePackContext();

  const defaultTenants = useMemo(() => {
    const defaultTenants: NumberTable = {
      [settings.BlipFoodsId]: 'blipfoods',
    };
    return defaultTenants;
  }, [settings]);

  const fetchPlugin = useCallback(
    async (packId: string) => {
      try {
        return await apiService.get<PluginData>(`${PLUGIN_ROUTE}/${packId}`, false);
      } catch (error) {
        console.error('Pack not Found on Plugin GET method');
      }
    },
    [apiService],
  );

  const fetchPack = useCallback(
    async (packId: string): Promise<Pack | undefined> => {
      try {
        const pack = await apiService.get<Application>(`${APPLICATION_ROUTE}/${packId}`, false);
        if (pack) {
          const plugin = pack.status == PackApprovalStatus.Approved ? await fetchPlugin(pack.id) : undefined;
          return {
            id: packId,
            tenant: { id: '' },
            channels: pack.channels,
            description: pack.description,
            name: pack.name,
            plugin: plugin,
            routerShortName: '',
            status: pack.status,
            version: pack.version,
            installed: false,
          };
        }
      } catch (error) {
        console.error('Pack not Found');
      }
    },
    [apiService, fetchPlugin],
  );

  const getTenantIdByPackId = (packId: string) => {
    if (!packId || packId == '') {
      return '';
    }
    return defaultTenants[parseInt(packId)] ?? '';
  };

  const getIsSkipChannelActivation = useCallback(() => {
    return skipActivation;
  }, [skipActivation]);

  return { fetchPack, getTenantIdByPackId, fetchPlugin, getIsSkipChannelActivation };
};
