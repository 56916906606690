import GirlIpad from '../../assets/GirlIpad.svg';
import { INSTALLATIONS_PROCESS_STATUS, INSTALL_CHATBOT_ROUTE } from '../../constants/ApiRoutes';
import {
  ROUTE_START_ACTIVATION,
  ROUTE_CONCLUSION,
  ROUTE_PLUGIN_INTEGRATION,
  ROUTE_ONBOARDING,
} from '../../constants/Routes';
import {
  SO_INSTALLATION,
  SO_INSTALLATION_BUTTON,
  SO_INSTALLATION_STATUS,
  SO_INSTALLATION_TIMEOUT,
} from '../../constants/TrackingEvents';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import { Step } from '../../enums';
import { usePack } from '../../hooks/usePack';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createToastError, createToastSuccess, createToastWarning } from '../../services/Toast';
import { Center, ContainerProgressBar, PageContainer } from '../../styles';
import { handleApiErrorResponseForTrack } from '../../utils';
import { ContainerActions } from './styles';

type StatusInstallation = {
  status: 'Pending' | 'In Progress' | 'Completed' | 'Canceled';
  installationId: number;
  errorCode: string;
};

type StartInstallation = {
  routerShortName?: string;
  installationId?: string;
};

type ProgressBar = {
  start: boolean;
  percent: number;
};

const Installation: React.FC = () => {
  const { profile, apiService, setActiveStep } = useAppContext();
  const { pack, setPack } = usePackContext();
  const [pollingEnabled, setPollingEnabled] = useState(false);
  const installationIntervalRef = useRef<NodeJS.Timer>();
  const progressBarIntervalRef = useRef<NodeJS.Timer>();
  const [progressBar, setProgressBar] = useState<ProgressBar>({ start: false, percent: 0 });
  const installedRef = useRef<boolean>(false);
  const { tracking } = useTrackNoEvent();
  const { getIsSkipChannelActivation } = usePack();
  const navigate = useNavigate();

  const installationFailureToast = () =>
    createToastError('Falha na preparação', 'Por favor, tente novamente daqui alguns minutos.');

  const getButtonName = () => {
    return !pack.installed && pack.installationId ? 'Continuar preparação' : 'Preparar';
  };

  const getStatusByPercent = () => {
    if (progressBar.percent < 10) return 'aguarde enquanto preparamos seu pack...';
    if (progressBar.percent < 20) return 'separando os materiais...';
    if (progressBar.percent < 40) return 'organizando as ferramentas...';
    if (progressBar.percent < 60) return 'preparando o ambiente...';
    else return 'deixando tudo pronto...';
  };

  const getLoadingText = () => {
    const formattedPercent = progressBar.percent.toFixed(2).replace('.', ',');
    return `${formattedPercent}% concluído - ${getStatusByPercent()}`;
  };

  const handleTimeout = useCallback(() => {
    tracking(SO_INSTALLATION_TIMEOUT);
    setPollingEnabled(false);
    createToastWarning(
      'Preparação pausada',
      'A preparação excedeu o tempo esperado. Tente continuar daqui alguns minutos.',
      12,
    );
  }, [tracking]);

  const handleNextRoute = useCallback(() => {
    if (pack.plugin?.url) {
      navigate(ROUTE_CONCLUSION);
    } else {
      navigate(getIsSkipChannelActivation() ? ROUTE_CONCLUSION : ROUTE_START_ACTIVATION);
    }
  }, [pack.plugin?.url, navigate, getIsSkipChannelActivation]);

  const completedInstallationPolling = useCallback(async () => {
    try {
      const params = { applicationId: pack.id, installations: pack.installationId };
      const data = await apiService.get<StatusInstallation[]>(`${INSTALLATIONS_PROCESS_STATUS}`, params, false);
      const installationStatusAlreadyExists = data.length > 0;

      if (installationStatusAlreadyExists) {
        if (data[0].status == 'Completed') {
          installedRef.current = true;
          setPollingEnabled(false);
          setPack((pack: any) => ({ ...pack, installed: true }));
          tracking(SO_INSTALLATION_STATUS, { status: 'Sucesso' });
        } else if (data[0].status == 'Canceled') {
          setPollingEnabled(false);
          setPack((pack: any) => ({ ...pack, routerShortName: '', installationId: '' }));
          installationFailureToast();
          tracking(SO_INSTALLATION_STATUS, { status: 'Falha', error: data[0].errorCode });
        }
      }
    } catch (error: any) {
      setPollingEnabled(false);
      createToastError('Falha ao obter o status da preparação', 'Por favor, tente novamente daqui alguns minutos.');
      tracking(SO_INSTALLATION_STATUS, { status: 'Falha', error: handleApiErrorResponseForTrack(error) });
    }
  }, [apiService, pack.id, pack.installationId, setPack, tracking]);

  const startInstallation = async () => {
    const body = {
      applicationId: pack.id,
      clientIdentity: pack.companyName,
      clientEmail: profile?.Email,
      tenant: pack.tenant.id,
    };

    try {
      setProgressBar({ start: true, percent: 0 });
      return await apiService.post<StartInstallation>(INSTALL_CHATBOT_ROUTE, body);
    } catch (error: any) {
      setProgressBar({ start: false, percent: 0 });

      const errorCode = error?.response?.data?.Title;
      if (errorCode == 'CLIENT_NAME_ALREADY_EXISTS_IN_PACK_INSTALLATION') {
        setPack((pack: any) => ({ ...pack, companyName: '' }));
        createToastWarning('Nome da empresa já existe', 'O nome não está disponível mais. Por favor, informe outro.');
        tracking(SO_INSTALLATION_STATUS, { status: 'Falha', error: 'Nome da empresa já existe' });
        navigate(ROUTE_ONBOARDING);
      } else {
        tracking(SO_INSTALLATION_STATUS, { status: 'Falha', error: handleApiErrorResponseForTrack(error) });
        installationFailureToast();
      }
    }
  };

  const handleClickInstallation = async () => {
    tracking(SO_INSTALLATION_BUTTON, { buttonName: getButtonName() });

    if (pack.installed) {
      return handleNextRoute();
    } else if (pack.installationId) {
      setPollingEnabled(true);
      setProgressBar({ start: true, percent: 0 });
    } else {
      const data = await startInstallation();
      if (data) {
        const { routerShortName, installationId } = data;

        setPack((pack: any) => ({ ...pack, routerShortName, installationId }));
        setPollingEnabled(true);
      }
    }
  };

  useEffect(() => {
    setActiveStep(Step.Preparation);
    tracking(SO_INSTALLATION);
  }, []);

  useEffect(() => {
    if (!pollingEnabled && !installedRef.current) {
      setProgressBar({ start: false, percent: 0 });
    }
  }, [pollingEnabled]);

  useEffect(() => {
    if (pollingEnabled) {
      if (!installationIntervalRef.current) {
        const timeoutInMilliseconds = 1000 * 60 * 6;
        const intervalInMilliseconds = 5 * 1000;
        let totalTime = 0;

        installationIntervalRef.current = setInterval(() => {
          completedInstallationPolling();
          totalTime += intervalInMilliseconds;

          if (totalTime >= timeoutInMilliseconds) {
            handleTimeout();
          }
        }, intervalInMilliseconds);
      }
    } else {
      clearInterval(installationIntervalRef.current);
      installationIntervalRef.current = undefined;
    }
  }, [pollingEnabled, setPack, completedInstallationPolling, handleTimeout]);

  useEffect(() => {
    if (progressBar.start) {
      if (!progressBarIntervalRef.current) {
        let currentProgress = 0.1;
        let delayDivider = 1;

        progressBarIntervalRef.current = setInterval(() => {
          currentProgress += Math.random() / delayDivider;

          if (currentProgress >= 100) {
            setProgressBar(prevState => ({ ...prevState, percent: 100 }));
            createToastSuccess('Preparação do Pack concluída com sucesso.', '', 5);
            handleNextRoute();
          } else {
            setProgressBar(prevState => ({ ...prevState, percent: currentProgress }));

            if (installedRef.current) {
              delayDivider = 0.25;
            } else {
              delayDivider += currentProgress > 40 ? 0.025 : 0.015;
            }
          }
        }, 250);
      }
    } else {
      setProgressBar({ start: false, percent: 0 });
      clearInterval(progressBarIntervalRef.current);
      progressBarIntervalRef.current = undefined;
    }
  }, [progressBar.percent, progressBar.start, setPack, handleNextRoute]);

  useEffect(() => {
    return () => {
      clearInterval(installationIntervalRef.current);
      clearInterval(progressBarIntervalRef.current);
    };
  }, []);

  return (
    <>
      <PageContainer>
        <div>
          <bds-typo tag="h4" variant="fs-32" bold="bold">
            Preparar Pack
          </bds-typo>
          <bds-typo tag="h4" variant="fs-14">
            Este processo pode levar alguns minutos.
          </bds-typo>
          {progressBar.start ? (
            <ContainerProgressBar>
              <bds-progress-bar percent={progressBar.percent} />
              <bds-typo variant="fs-12" bold="bold">
                {getLoadingText()}
              </bds-typo>
            </ContainerProgressBar>
          ) : (
            <ContainerActions>
              <bds-button onClick={handleClickInstallation}>{getButtonName()}</bds-button>
            </ContainerActions>
          )}
        </div>
        <div>
          <Center>
            <img src={GirlIpad} alt="Pack Preparation" />
          </Center>
        </div>
      </PageContainer>
    </>
  );
};

export default Installation;
