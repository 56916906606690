import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;

  > bds-stepper {
    margin: 20px 0;
    display: block;
  }
`;

export const ContentContainer = styled.div`
  max-width: 960px;
  margin: 44px auto;
`;
