import BoyComputer from '../../../assets/BoyComputer.svg';
import { ROUTE_NUMBER_ACTIVATION } from '../../../constants/Routes';
import { SO_EMBEDDED_SIGNUP_STATUS, SO_LINK, SO_OPEN_EMBEDDED_SIGNUP } from '../../../constants/TrackingEvents';
import { Step } from '../../../enums';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { launchWhatsAppSignup } from '../../../services/FacebookWrapper';
import { Center, HelpLink, PageContainer } from '../../../styles';
import { PageLeftContainer } from './styles';
import { useAppContext } from '../../../contexts/AppContext';

const EmbeddedSignup: React.FC = () => {
  const navigate = useNavigate();
  const { facebookAppId, setActiveStep, setWhatsAppInfo } = useAppContext();
  const { tracking } = useTrackNoEvent();

  const facebookLoginCallback = (inputToken: string) => {
    const isSuccess = inputToken != undefined && inputToken != '';

    tracking(SO_EMBEDDED_SIGNUP_STATUS, {
      status: isSuccess ? 'Sucesso' : 'Sem sucesso',
    });

    if (isSuccess) {
      setWhatsAppInfo((whatsAppInfo: any) => ({ ...whatsAppInfo, inputToken: inputToken }));
      navigate(ROUTE_NUMBER_ACTIVATION);
    }
  };
  const openFacebookLogin = async () => {
    tracking(SO_OPEN_EMBEDDED_SIGNUP);
    launchWhatsAppSignup(facebookAppId, facebookLoginCallback);
  };

  const handleFacebookLogin = () => {
    tracking(SO_LINK, {
      screenName: 'Ativação no WhatsApp',
      linkName: 'clique aqui embedded signup',
    });
    openFacebookLogin();
  };

  const handleFacebookHelpLink = () => {
    tracking(SO_LINK, {
      screenName: 'Ativação no WhatsApp',
      linkName: 'clique aqui documentação',
    });
  };

  const renderFacebokLoginLink = () => {
    const link = (
      <a id="facebookLogin" onClick={handleFacebookLogin}>
        clique aqui.
      </a>
    );
    return link;
  };

  useEffect(() => {
    setActiveStep(Step.WhatsAppActivation);
    openFacebookLogin();
  }, []);

  return (
    <PageContainer>
      <PageLeftContainer>
        <bds-typo variant="fs-32" bold="bold" tag="h2">
          Login no Facebook
        </bds-typo>
        <bds-typo>
          Uma janela de login no Facebook deve ter aparecido por aí. Você precisará acessar e fazer algumas
          configurações por lá.
        </bds-typo>
        <br />
        <bds-typo>
          Mas não precisa se preocupar! Quando tudo estiver pronto, nós atualizamos as informações para continuar seu
          cadastro por aqui. 😊
        </bds-typo>
        <br />
        <bds-typo>
          Se nenhuma janela apacecer,&nbsp;
          {renderFacebokLoginLink()}
        </bds-typo>
        <div>
          <bds-typo variant="fs-12">
            Quer saber mais sobre a configuração no Facebook?&nbsp;
            <HelpLink
              id="helpLink"
              href="https://www.youtube.com/watch?v=vEt9l0WmHI8"
              onClick={handleFacebookHelpLink}
              target="_blank"
              rel="noreferrer"
            >
              clique aqui
            </HelpLink>
          </bds-typo>
        </div>
      </PageLeftContainer>
      <Center>
        <img src={BoyComputer} alt="Ativar WhatsApp" />
      </Center>
    </PageContainer>
  );
};

export default EmbeddedSignup;
