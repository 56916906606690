import blipColors from 'blip-tokens/build/json/colors.json';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 1100px;
  margin: auto auto 0 auto;
  img {
    margin-right: 24px;
  }
  bds-typo {
    text-align: center;
    color: ${blipColors['color-extend-grays-street']};
  }
`;

export const Background = styled.div`
  background-color: ${blipColors['color-neutral-light-whisper']};
  position: fixed;
  bottom: 0;
  width: 100%;
`;
