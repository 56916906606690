import axios from 'axios';
import { GraphApiUrl } from '../constants/GraphApi';
import { SO_API_CALL, SO_API_CALL_ERROR } from '../constants/TrackingEvents';
import { PhoneNumber, Waba } from '../interfaces/Waba';
import { BusinessProfileBody } from '../typings/BusinessProfileWpp';
import { Configuration } from '../typings/Configuration';
import { logTrack } from './LogTrackService';

const getWabaPhoneNumbersById = async (wabaId: string, settings: Configuration): Promise<PhoneNumber[]> => {
  const url = `${GraphApiUrl}/${wabaId}/phone_numbers?fields=display_phone_number&access_token=${settings.FacebookBusinessSystemUserToken}`;
  const response = await fetch(url);

  if (!response.ok) {
    logTrack(SO_API_CALL, {
      apiCall: 'GRAPH API: getWabaPhoneNumbersById',
      status: 'Falha',
      error: JSON.stringify(response),
    });
    return [];
  }

  logTrack(SO_API_CALL, { apiCall: 'GRAPH API: getWabaPhoneNumbersById', status: 'Sucesso' });

  const data = await response.json();
  const phoneNumbers: PhoneNumber[] = data.data.map(({ display_phone_number, id }: any) => ({
    displayPhoneNumber: display_phone_number,
    id,
  }));
  return phoneNumbers;
};

// input_token => token-returned-from-signup-flow
// accessToken => system-user-access-token

const getWabaIdsAndAppName = async (
  inputToken: string,
  settings: Configuration,
): Promise<{ wabaIds: string[]; applicationName: string }> => {
  const url = `${GraphApiUrl}/debug_token?input_token=${inputToken}&access_token=${settings.FacebookBusinessSystemUserToken}`;
  const response = await fetch(url);

  if (!response.ok) {
    logTrack(SO_API_CALL, {
      apiCall: 'GRAPH API getWabaIdsAndAppName',
      status: 'Falha',
      error: JSON.stringify(response),
    });
    return Promise.reject(new Error(JSON.stringify(response)));
  }

  const data = await response.json();

  try {
    const waScope = data.data.granular_scopes.find(({ scope }: any) => scope === 'whatsapp_business_management');
    return Promise.resolve({ wabaIds: waScope.target_ids, applicationName: data.data.application });
  } catch (error) {
    console.error('Error finding Waba granular scopes: ', error);
    return Promise.reject(new Error(error as string));
  }
};

export const getWabas = async (
  inputToken: string,
  settings: Configuration,
): Promise<{ wabas: Waba[]; applicationName: string }> => {
  const wabas: Waba[] = [];

  try {
    const response = await getWabaIdsAndAppName(inputToken, settings);
    logTrack(SO_API_CALL, { apiCall: 'GRAPH API getWabaIdsAndAppName', status: 'Sucesso' });
    for (const wabaId of response.wabaIds) {
      const wabaPhoneNumbers = await getWabaPhoneNumbersById(wabaId, settings);
      wabas.push({
        id: wabaId,
        phoneNumbers: wabaPhoneNumbers,
      });
    }
    return Promise.resolve({ wabas: wabas, applicationName: response.applicationName });
  } catch (error) {
    logTrack(SO_API_CALL_ERROR, {
      apiCall: 'GRAPH API getWabaIdsAndAppName',
      status: 'Falha',
      error: JSON.stringify(error as string),
    });
    return Promise.reject(new Error(error as string));
  }
};

export const UpdateWppBusinessProfile = async (
  access_token: string,
  phoneNumberId: string,
  body: BusinessProfileBody,
) => {
  const url = `${GraphApiUrl}/${phoneNumberId}/whatsapp_business_profile`;

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    logTrack(SO_API_CALL, { apiCall: 'GRAPH API: UpdateWppBusinessProfile', status: 'Sucesso' });
    return Promise.resolve(response.data);
  } catch (error) {
    logTrack(SO_API_CALL_ERROR, {
      apiCall: 'GRAPH API: UpdateWppBusinessProfile',
      status: 'Falha',
      error: error as string,
    });
    return Promise.reject(new Error(error as string));
  }
};
