import BlipWhatsApp from '../../../assets/WhatsappChatbot.svg';
import { ROUTE_ACTIVATION, ROUTE_EMBEDDED_SIGNUP, ROUTE_START_ACTIVATION } from '../../../constants/Routes';
import { SO_ACTIVATED, SO_START_ACTIVATION, SO_STEPBACK } from '../../../constants/TrackingEvents';
import { Step } from '../../../enums';
import { useSession } from '../../../hooks/useSession';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Center, HelpLink, PageContainer } from '../../../styles';
import { BottomInfo, ContainerActions } from './styles';
import { useAppContext } from 'Self-onboading/contexts/AppContext';

const WhatsAppBusinessInfo: React.FC = () => {
  const navigate = useNavigate();
  const { setActiveStep } = useAppContext();
  const { tracking } = useTrackNoEvent();
  const { saveSession } = useSession();

  const handleStartActivation = () => {
    tracking(SO_START_ACTIVATION, {
      screenName: 'Ativação no WhatsApp',
    });
    navigate(ROUTE_EMBEDDED_SIGNUP);
  };

  const handleStepBack = () => {
    tracking(SO_STEPBACK, {
      screenName: 'Ativação no WhatsApp',
    });
    navigate(ROUTE_START_ACTIVATION);
  };

  useEffect(() => {
    tracking(SO_ACTIVATED);
    setActiveStep(Step.WhatsAppActivation);
    saveSession();
    (window as any).gtag('event', 'conversion', { send_to: 'AW-874108198/km2kCJXJn5wYEKaq56AD' });
  }, []);

  return (
    <PageContainer>
      <div>
        <bds-typo variant="fs-32" bold="bold" tag="h2">
          Primeiro vamos ativar o WhatsApp no seu Pack
        </bds-typo>
        <bds-typo>
          Nas próximas etapas nós vamos conectar o seu Gerenciador de Negócios do Facebook e configurar o número de
          contato do WhatsApp.
          <b> É muito importante que você faça login como administrador.</b>
        </bds-typo>
        <br />
        <bds-typo>
          Ainda não tem um Gerenciador de Negócios? Não precisa se preocupar! Você pode criá-lo durante o processo.
        </bds-typo>
        <ContainerActions>
          <bds-button icon="arrow-left" id="stepBack" variant="tertiary" onClick={handleStepBack}>
            Voltar
          </bds-button>
          <bds-button id="startActivation" variant="primary" arrow={true} onClick={handleStartActivation}>
            Iniciar a ativação
          </bds-button>
        </ContainerActions>
        <BottomInfo>
          <bds-typo variant="fs-12">
            Quer saber mais sobre o Gerenciador de Negócios do Facebook?&nbsp;
            <HelpLink
              id="helpLink"
              href="https://www.facebook.com/business/tools/business-manager"
              target="_blank"
              rel="noreferrer"
            >
              clique aqui
            </HelpLink>
          </bds-typo>
        </BottomInfo>
      </div>
      <Center>
        <img src={BlipWhatsApp} alt="Blip Pack" />
      </Center>
    </PageContainer>
  );
};

export default WhatsAppBusinessInfo;
