import blipColors from 'blip-tokens/build/json/colors.json';
import styled from 'styled-components';

export const Container = styled.div`
  text-align: -webkit-center;
  align-items: center;

  bds-typo {
    color: ${blipColors['color-neutral-dark-city']};
  }

  img {
    height: 150px;
  }
`;

export const InputArea = styled.div`
  align-items: center;
  display: inline-flex;
  width: 450px;
  text-align: center;
  margin: 24px 0;

  bds-typo {
    margin-right: 16px;
  }
`;
