import styled from 'styled-components';

export const ModalContainer = styled.div`
    margin: 20px;
`;

export const ModalContent = styled.div`
    margin: 24px 0;
    max-height: 360px;
    padding: 10px;
    overflow-y: auto;
    
    .disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    div:not(.disabled):hover {
        background-color: #F8FBFB;
        cursor: pointer;
    }
`;

export const ModalLoadingContent = styled.div`
    margin: 50px 0;
    min-width: 150px;
    text-align: center;
`;

export const ModalAction = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
`;

export const RadioItem = styled.div<{ active: boolean }>`
  padding: 14px 24px;
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow: 0px 2px 8px rgba(7, 71, 166, 0.15);
  border: ${props => `2px solid ${props.active ? '#3F7DE8' : 'transparent'}`};
`;

export const RadioContent = styled.div`
  display: flex;
  align-items: center;
  min-width: 460px; //fix width when have tooltip
  column-gap: 20px;

  bds-radio {
    pointer-events: none;
  }

  bds-chip-tag {
    margin-left: auto;
  }
`;

export const RadioTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
`;

export const RadioSubTitle = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: #6E7B91;
    display: block;
`;
