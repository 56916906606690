import colors from 'blip-tokens/build/json/colors.json';
import styled from 'styled-components';

export const LeftColumn = styled.div`
  align-self: center;
  max-width: 450px;

  > * {
    margin: 16px 0;
  }

  bds-button {
    float: right;
  }
`;

export const NextStep = styled.div`
  > div {
    display: flex;
  }

  bds-typo {
    margin: 16px 24px;
    width: 550px;
  }

  img {
    margin: 8px;
    width: 73px;
    height: 64px;
  }

  bds-button {
    margin-top: 16px;
  }
`;

export const Hr = styled.hr`
  border-top: 1px solid ${colors['color-neutral-light-box']};
  width: 100vw;
  margin: 32px -50vw;
  position: relative;
  left: 50%;
  right: 50%;
`;
