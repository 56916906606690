import styled from 'styled-components';

export const ContainerActions = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 24px;
`;

export const TitleContainer = styled.div`
  bds-tooltip {
    position: absolute;
  }

  bds-icon {
    margin-left: 4px;
  }
`;