import BlipWhatsApp from '../../../assets/WhatsappChatbot.svg';
import colors from 'blip-tokens/build/json/colors.json';
import { ROUTE_ACTIVATION, ROUTE_WHATSAPP_BUSINESS } from '../../../constants/Routes';
import {
  SO_ACTIVATION_BUTTON,
  SO_STEPBACK,
  SO_STEPBACK_ATTENTION,
  SO_WHATSAPP_CONFIGURATED,
} from '../../../constants/TrackingEvents';
import { useAppContext } from '../../../contexts/AppContext';
import { Step } from '../../../enums';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import { PhoneNumber, Waba } from '../../../interfaces/Waba';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createToastError } from '../../../services/Toast';
import { getWabas } from '../../../services/WhatsAppService';
import { Center, PageContainer } from '../../../styles';
import { isNumber } from '../../../utils';
import { ContainerActions, ContainerFormLabel, Form, PinContainer } from './styles';

const NumberActivation: React.FC = () => {
  const navigate = useNavigate();
  const { whatsAppInfo, settings, setActiveStep, setWhatsAppInfo } = useAppContext();
  const [pin, setPin] = useState({ value: '', showValue: false });
  const [showConfirmationToBack, setShowConfirmationToBack] = useState(false);
  const [phoneNumberOptions, setPhoneNumberOptions] = useState<PhoneNumber[]>();
  const [wabas, setWabas] = useState<Waba[]>();
  const { tracking } = useTrackNoEvent();

  const handleChangePin = (e: any) => setPin({ ...pin, value: e.target.value });
  const handleShowPin = () => setPin({ ...pin, showValue: !pin.showValue });

  const handleStepBack = () => {
    tracking(SO_STEPBACK, {
      screenName: 'Ativação no WhatsApp',
    });
    setShowConfirmationToBack(true);
  };

  const handleKeyPressPin = (e: any) => {
    if (!isNumber(e.key)) {
      e.preventDefault();
    }
  };

  const handleFormIsValid = () => {
    return whatsAppInfo.phoneNumber?.displayPhoneNumber != '' && isNumber(pin.value) && pin.value.length == 6;
  };

  const handleConfirmationToBack = (confirm: boolean) => {
    tracking(SO_STEPBACK_ATTENTION, {
      screenName: 'Ativação no WhatsApp',
      buttonName: confirm ? 'Sim' : 'Cancelar',
    });
    setShowConfirmationToBack(false);

    if (confirm) {
      setWhatsAppInfo(whatsAppInfo => ({ ...whatsAppInfo, inputToken: '' }));
      navigate(ROUTE_WHATSAPP_BUSINESS);
    }
  };

  const handleSelectedNumber = useCallback(
    (phoneNumber: PhoneNumber) => {
      if (wabas) {
        wabas.forEach(waba => {
          const wabaFromNumber = waba.phoneNumbers.find(
            (number: PhoneNumber) => number.displayPhoneNumber == phoneNumber.displayPhoneNumber,
          );
          if (wabaFromNumber) {
            phoneNumber.wabaId = waba.id;
          }
        });
      }

      setWhatsAppInfo((whatsAppInfo: any) => ({
        ...whatsAppInfo,
        phoneNumber: {
          displayPhoneNumber: phoneNumber.displayPhoneNumber.replace('+', '').replace('-', '').replaceAll(' ', ''),
          id: phoneNumber.id,
          wabaId: phoneNumber.wabaId,
        },
      }));
    },
    [setWhatsAppInfo, wabas],
  );

  const handleActivateNumber = () => {
    tracking(SO_ACTIVATION_BUTTON, {
      screenName: 'Ativação no WhatsApp',
    });
    navigate(ROUTE_ACTIVATION, { state: { pin: pin.value } });
  };

  const loadPhoneNumbers = useCallback(async () => {
    try {
      const { wabas, applicationName } = await getWabas(whatsAppInfo.inputToken, settings);
      const phoneNumbers = wabas.map(waba => waba.phoneNumbers).reduce((a, b) => a.concat(b));
      const uniquePhoneNumbers = phoneNumbers.filter((value, index, array) => array.indexOf(value) === index).sort();
      setWhatsAppInfo((whatsAppInfo: any) => ({ ...whatsAppInfo, applicationName: applicationName }));
      setWabas(wabas);
      setPhoneNumberOptions(uniquePhoneNumbers);
    } catch (error) {
      createToastError('Falha ao obter os números', 'Não foi possível obter os números da conta do WhatsApp Business.');
    }
  }, [setWabas, whatsAppInfo.inputToken, settings, setWhatsAppInfo]);

  useEffect(() => {
    tracking(SO_WHATSAPP_CONFIGURATED, {});
    setActiveStep(Step.WhatsAppActivation);
    loadPhoneNumbers();
  }, [loadPhoneNumbers]);

  useEffect(() => {
    const selectListener = document.querySelector('bds-select');

    selectListener?.addEventListener('optionSelected', (event: any) => {
      handleSelectedNumber(JSON.parse(event.detail.value) as PhoneNumber);
    });

    return () =>
      selectListener?.removeEventListener('optionSelected', (event: any) => {
        handleSelectedNumber(JSON.parse(event.detail.value) as PhoneNumber);
      });
  }, [handleSelectedNumber, phoneNumberOptions]);

  return (
    <>
      {phoneNumberOptions ? (
        <PageContainer>
          <div>
            <bds-typo variant="fs-32" bold="bold" tag="h2">
              Legal! Agora a conta do WhatsApp está configurada. 🥳
            </bds-typo>
            <Form>
              <label>Escolha qual número do WhatsApp você quer ativar.</label>
              <bds-select
                placeholder="Escolha um número..."
                id="phoneNumber"
                label="Número do WhatsApp"
                icon="smartphone"
              >
                {phoneNumberOptions?.map((phoneNumber: PhoneNumber, index) => {
                  return (
                    <bds-select-option value={JSON.stringify(phoneNumber)} key={index}>
                      {phoneNumber.displayPhoneNumber}
                    </bds-select-option>
                  );
                })}
              </bds-select>
              <ContainerFormLabel>
                <label>
                  Cadastre um <b>PIN</b>.
                </label>
                <bds-tooltip
                  position="right-center"
                  tooltip-text="PIN é uma senha de 6 dígitos criada para aumentar a segurança da sua conta do WhatsApp."
                >
                  <bds-icon
                    color="black"
                    name="info"
                    size="x-small"
                    theme="solid"
                    aria-label="Ícone de informações"
                  ></bds-icon>
                </bds-tooltip>
              </ContainerFormLabel>
              <PinContainer>
                <bds-input
                  type={pin.showValue ? 'text' : 'password'}
                  id="pin"
                  placeholder="Digite um PIN de 6 dígitos..."
                  icon="lock"
                  label="PIN"
                  required
                  minlength-error-message="O campo requer 6 dígitos."
                  requiredErrorMessage="Campo obrigatório."
                  maxlength={6}
                  minlength={6}
                  onInput={handleChangePin}
                  onPaste={(e: { preventDefault: () => void }) => e.preventDefault()}
                  onKeyPress={handleKeyPressPin}
                />
                <bds-icon
                  color={colors['color-neutral-medium-cloud']}
                  name={pin.showValue ? 'eye-open' : 'eye-closed'}
                  theme="outline"
                  size="small"
                  onClick={handleShowPin}
                />
              </PinContainer>
              <ContainerActions>
                <bds-button icon="arrow-left" variant="tertiary" onClick={handleStepBack}>
                  Voltar
                </bds-button>
                <bds-button
                  id="activateNumber"
                  variant="primary"
                  disabled={!handleFormIsValid()}
                  arrow={true}
                  onClick={handleActivateNumber}
                >
                  Ativar o número
                </bds-button>
              </ContainerActions>
            </Form>
          </div>
          <Center>
            <img src={BlipWhatsApp} alt="Blip Pack" />
          </Center>
        </PageContainer>
      ) : (
        <bds-loading-bar percent={100} />
      )}

      <bds-alert open={showConfirmationToBack}>
        <bds-alert-header variant="warning" icon="warning">
          Atenção: suas informações não serão salvas!
        </bds-alert-header>
        <bds-alert-body>
          Você <b>perderá toda a configuração</b> feita até aqui. Quer mesmo voltar?
        </bds-alert-body>
        <bds-alert-actions>
          <bds-button
            id="confirmAlert"
            variant="tertiary"
            onClick={() => {
              handleConfirmationToBack(true);
            }}
          >
            Sim
          </bds-button>
          <bds-button
            id="cancelAlert"
            variant="primary"
            onClick={() => {
              handleConfirmationToBack(false);
            }}
          >
            Cancelar
          </bds-button>
        </bds-alert-actions>
      </bds-alert>
    </>
  );
};

export default NumberActivation;
