import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const RouteGuard = ({ isAllowed, children = <Outlet /> }: any) => {
  if (isAllowed) {
    return children;
  }

  return <Navigate to="/" replace />;
};

export default RouteGuard;
