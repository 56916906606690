import { TENANTS_ROUTE } from '../../constants/ApiRoutes';
import { useAppContext } from '../../contexts/AppContext';
import { Plan } from '../../enums';
import React, { useCallback, useEffect, useState } from 'react';
import { createToastError } from '../../services/Toast';
import { FlexColumn } from '../../styles';
import { Tenant } from '../../typings/Tenant';
import {
  ModalAction,
  ModalContent,
  ModalLoadingContent,
  RadioContent,
  RadioItem,
  RadioSubTitle,
  RadioTitle,
} from './styles';

type TenantModalProps = {
  isOpen: boolean;
  onConfirm?: (tenant: Tenant) => void;
};

const TenantModal: React.FC<TenantModalProps> = ({ isOpen, onConfirm: OnConfirmCallback }) => {
  const { apiService, settings } = useAppContext();
  const [tenants, setTenants] = useState<Tenant[] | undefined>(undefined);
  const [isLoading, setIsloading] = useState(true);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | undefined>(undefined);

  const hasTenants = () => tenants && tenants.length > 0;
  const handleSelectedTenant = (tenant: Tenant) => setSelectedTenant(tenant);
  const handleOnConfirm = () => {
    if (selectedTenant) {
      OnConfirmCallback && OnConfirmCallback(selectedTenant);
    }
  };

  const loadTenants = useCallback(async () => {
    try {
      const tenants = await apiService.get<Tenant[]>(TENANTS_ROUTE, { includePlan: true });
      if (tenants) {
        setTenants(tenants);
      }
    } catch (error) {
      createToastError('Falha ao obter os contratos', 'Por favor, aguarde alguns minutos e atualize a página.');
    } finally {
      setIsloading(false);
    }
  }, [apiService]);

  const renderHeader = () => {
    return (
      <div>
        <bds-typo variant="fs-20" bold="bold">
          {hasTenants()
            ? 'Olá! Percebemos que você já possui contratos no Blip.'
            : 'Olá! Você não possui contratos no Blip.'}
        </bds-typo>
        <bds-typo variant="fs-14">
          {hasTenants()
            ? 'Por favor, selecione qual gostaria de usar para ativar seu Pack.'
            : 'Para continuar com a ativação do Pack é necessário um contrato.'}
        </bds-typo>
      </div>
    );
  };

  const renderTenants = () => {
    return tenants?.map((item: Tenant, index: number) => {
      const selected = selectedTenant?.id == item.id;
      const isFreePlan = item.plan?.name == Plan.Standard;
      const tenantDescription = `${item.id}${settings.Environment == 'staging' ? '.hmg' : ''}.portal.blip.ai`;

      const radioContent = (
        <RadioContent>
          <bds-radio checked={selected} value={`tenant${index}`} label=""></bds-radio>
          <div>
            <RadioTitle>{item.name}</RadioTitle>
            <RadioSubTitle>{tenantDescription}</RadioSubTitle>
          </div>
          <bds-chip-tag color={isFreePlan ? 'outline' : 'info'}>{item.plan?.name.toUpperCase()}</bds-chip-tag>
        </RadioContent>
      );

      return (
        <RadioItem key={index} active={selected} onClick={() => handleSelectedTenant(item)}>
          {radioContent}
        </RadioItem>
      );
    });
  };

  useEffect(() => {
    loadTenants();
  }, [loadTenants]);

  return (
    <bds-modal open={isOpen} close-button="false" size="dynamic">
      {isLoading ? (
        <FlexColumn>
          <ModalLoadingContent>
            <bds-loading-spinner size="small" />
          </ModalLoadingContent>
        </FlexColumn>
      ) : (
        <FlexColumn>
          {renderHeader()}
          <ModalContent>{renderTenants()}</ModalContent>
          {hasTenants() && (
            <ModalAction>
              <bds-button disabled={!selectedTenant} onClick={handleOnConfirm}>
                Concluir
              </bds-button>
            </ModalAction>
          )}
        </FlexColumn>
      )}
    </bds-modal>
  );
};

export default TenantModal;
