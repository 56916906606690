import { GET_INSTALLATION_BY_SHORTNAME } from '../../constants/ApiRoutes';
import { ROUTE_CONCLUSION, ROUTE_START_ACTIVATION } from '../../constants/Routes';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import { Step } from '../../enums';
import { usePack } from '../../hooks/usePack';
import { usePluginEventsHandler } from 'hooks/usePluginEventsHandler';
import { useSession } from '../../hooks/useSession';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InjectMicrofrontEnd } from 'services/MicrofrontEnd';
import { createToastError } from '../../services/Toast';
import { ContainerPlugin, MainContainer } from './styles';

const PluginIntegration: React.FC = () => {
  usePluginEventsHandler();
  const { setActiveStep, profile, apiService, whatsAppInfo } = useAppContext();
  const { pack } = usePackContext();
  const [isReady, setIsReady] = useState(false);
  const { getIsSkipChannelActivation } = usePack();
  const navigate = useNavigate();
  const { saveSession } = useSession();
  const [pluginProps, setPluginProps] = useState<{
    userData: any;
    isSelfOnboarding: boolean;
    routerData: any;
    tenantId: any;
    companyName: any;
    activationOption: string;
  }>({
    userData: {
      sid: '',
      sub: '',
      auth_time: 0,
      idp: '',
      amr: ['external'],
      preferred_username: '',
      name: '',
      email: '',
      ProviderDisplayName: '',
      FullName: '',
      UserName: '',
      Name: '',
      SurName: '',
      culture: '',
      CompanySite: '',
      PhoneNumber: '',
      CreatedByNewAccountRegister: '',
      Email: '',
      EmailConfirmed: '',
    },
    isSelfOnboarding: true,
    routerData: {
      shortName: '',
      accessKey: '=',
      skillTransbordo: {
        shortName: null,
        accessKey: null,
      },
    },
    tenantId: 'blip-foods-pack',
    companyName: '',
    activationOption: '',
  });

  const getInstallationInfo = (installation: any) => {
    try {
      return {
        shortName: installation.routerShortName,
        accessKey: installation.routerAccessKey,

        skillTransbordo: {
          shortName: installation.deskShortName,
          accessKey: installation.deskAccessKey,
        },
      };
    } catch {
      createToastError('Erro ao pesquisar instalações', 'Usuário não possui instalações deste pack');
    }
  };

  useEffect(() => {
    saveSession();
    window.addEventListener('SO_NEXT_STEP', () =>
      navigate(getIsSkipChannelActivation() ? ROUTE_CONCLUSION : ROUTE_START_ACTIVATION),
    );

    setActiveStep(Step.Preparation);

    if (pack.plugin?.url) {
      apiService.get(GET_INSTALLATION_BY_SHORTNAME, { shortName: pack.routerShortName }).then((installation: any) => {
        setPluginProps({
          userData: profile,
          isSelfOnboarding: true,
          routerData: getInstallationInfo(installation),
          tenantId: pack.tenant.id,
          companyName: pack.companyName,
          activationOption: whatsAppInfo.numberSituation,
        });

        if (pluginProps?.routerData && pack?.plugin) {
          //InjectMicrofrontEnd('container-plugins', pack.plugin.url, pack.plugin.tag, pluginProps);
          setIsReady(true);
        }
      });
    }

    return () => {
      window.removeEventListener('SO_NEXT_STEP', () =>
        navigate(getIsSkipChannelActivation() ? ROUTE_CONCLUSION : ROUTE_START_ACTIVATION),
      );
    };
  }, [setActiveStep]);

  return (
    <>
      <div>
        <MainContainer>
          <div>
            <bds-typo variant="fs-24" bold="bold" tag="h2">
              Precisamos configurar algumas coisas para começar...
            </bds-typo>
            <ContainerPlugin></ContainerPlugin>
          </div>
        </MainContainer>
      </div>
    </>
  );
};

export default PluginIntegration;
