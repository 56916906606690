import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  img {
    width: 100%;
  }
  img:nth-child(2) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
