import { ROUTE_CONCLUSION, ROUTE_PLUGIN_INTEGRATION } from '../../../constants/Routes';
import { SO_NEXT, SO_WHATSAPP_CONFIGURATION } from '../../../constants/TrackingEvents';
import { useAppContext } from '../../../contexts/AppContext';
import { usePackContext } from '../../../contexts/PackContext';
import { Step } from '../../../enums';
import { useSession } from '../../../hooks/useSession';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InitiateUpload } from '../../../services/ResumableApi';
import { UpdateWppBusinessProfile } from '../../../services/WhatsAppService';
import { BusinessProfileBody } from '../../../typings/BusinessProfileWpp';
import { Image } from '../../../typings/Image';
import {
  ConfigurationFields,
  ContainerActions,
  FirstFields,
  InputsLeft,
  InputsRight,
  PageConfiguration,
  PageContainer,
  UploadImage,
} from './styles';

const Configuration: React.FC = () => {
  const [whatsAppForm, setWhatsAppForm] = useState<BusinessProfileBody>({ messaging_product: 'whatsapp' });
  const { facebookAppId, settings, whatsAppInfo, setActiveStep } = useAppContext();
  const { pack } = usePackContext();
  const [image, setImage] = useState<File>();
  const { tracking } = useTrackNoEvent();
  const [loading, setLoading] = useState(false);
  const { saveSession } = useSession();

  const navigate = useNavigate();
  const INPUT_SITE = 'site';
  const INPUT_EMAIL = 'email';
  const INPUT_ADDRESS = 'address';
  const INPUT_DESCRIPTION = 'description';
  const INPUT_ABOUT = 'about';

  useEffect(() => {
    setActiveStep(Step.WhatsAppActivation);
    tracking(SO_WHATSAPP_CONFIGURATION);
    saveSession();
  }, [setActiveStep]);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  // Commented lines should be implemented when #387270 is finished

  // const handleImage = async (imageFile: File) => {
  //   setImage(imageFile);

  //   const imageHandler = await InitiateUpload(
  //     settings.FacebookBusinessSystemUserToken,
  //     facebookAppId,
  //     imageFile.size,
  //     imageFile.type as Image,
  //     imageFile,
  //   );

  //   if (imageHandler == 'error') {
  //     setImage(undefined);
  //     return;
  //   }

  //   setWhatsAppForm(form => ({
  //     ...form,
  //     profile_picture_handle: imageHandler,
  //   }));
  // };

  const handleChange = async (e: any) => {
    const callbacks: { [key: string]: () => void } = {
      site: () => setWhatsAppForm(form => ({ ...form, websites: [e.target.value] })),
      email: () => setWhatsAppForm(form => ({ ...form, email: e.target.value })),
      address: () => setWhatsAppForm(form => ({ ...form, address: e.target.value })),
      description: () => setWhatsAppForm(form => ({ ...form, description: e.target.value })),
      about: () => setWhatsAppForm(form => ({ ...form, about: e.target.value })),
    };

    callbacks[e.target.inputName]();
  };

  const updateProfileAndRedirect = async () => {
    setLoading(true);
    await UpdateWppBusinessProfile(whatsAppInfo.inputToken, whatsAppInfo.phoneNumber.id, whatsAppForm);
    setLoading(false);

    tracking(SO_NEXT, {
      screenName: 'Configuração do WhatsApp',
      buttonName: 'Próximo',
    });

    return navigate(ROUTE_CONCLUSION);
  };

  return (
    <>
      <PageContainer>
        <div>
          <bds-typo tag="h2" variant="fs-24" bold="bold">
            Quais informações irão aparecer no seu contato do WhatsApp?
          </bds-typo>
          <PageConfiguration>
            <bds-typo tag="p" variant="fs-14">
              Essas informações serão exibidas no seu perfil de contato do WhatsApp. Todos os seus clientes poderão
              visualiza-las.
            </bds-typo>
            <ConfigurationFields>
              <InputsLeft>
                {/* <FirstFields> */}
                {/* <UploadImage>
                    {image ? (
                      <img id="image" src={URL.createObjectURL(image)} onClick={handleClick} />
                    ) : (
                      <bds-button-icon variant="secondary" icon="upload" onClick={handleClick} />
                    )}

                    <input
                      accept="image/*"
                      type="file"
                      ref={hiddenFileInput}
                      onChange={ev => ev.target.files && handleImage(ev.target.files[0])}
                      style={{ display: 'none' }}
                    />
                  </UploadImage> */}
                <bds-input label="Nome de exibição" disabled value={whatsAppInfo.applicationName} />
                {/* </FirstFields> */}
                <bds-input label="Site" onInput={handleChange} input-name={INPUT_SITE} />
                <bds-input label="E-mail da empresa" onInput={handleChange} input-name={INPUT_EMAIL} />
                <bds-input label="Endereço" onInput={handleChange} input-name={INPUT_ADDRESS} />
              </InputsLeft>
              <InputsRight>
                <bds-input
                  label="Descrição"
                  is-textarea
                  rows={5}
                  onInput={handleChange}
                  input-name={INPUT_DESCRIPTION}
                />
                <bds-input label="Status" is-textarea rows={5} onInput={handleChange} input-name={INPUT_ABOUT} />
              </InputsRight>
            </ConfigurationFields>
          </PageConfiguration>
          <ContainerActions>
            <bds-button arrow={true} onClick={updateProfileAndRedirect} bds-loading={loading}>
              Próximo
            </bds-button>
          </ContainerActions>
        </div>
      </PageContainer>
    </>
  );
};

export default Configuration;
