import styled from 'styled-components';

export const ContainerPlugin = styled.div`
  color: #202c44;
  column-gap: 1.5rem;
  align-items: center;
  height: calc(100% - 40px);
`;

export const MainContainer = styled.div`
  display: grid;
  color: #202c44;
`;
