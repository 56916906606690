import colors from 'blip-tokens/build/json/colors.json';
import styled from 'styled-components';

export const CardsContainer = styled.div`
  margin-top: 24px;

  bds-radio-group {
    display: flex;
    gap: 16px;
    bds-radio {
      margin: 0 4px;
    }
  }

  bds-icon {
    margin: 0 4px;
  }

  bds-paper {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 232px;
    height: 95px;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
  bds-paper:hover {
    box-shadow: rgb(7 71 166 / 12%) 0px 10px 16px; /* Primary */
  }
`;

export const TipsContainer = styled.div`
  margin-top: 24px;
  background-color: ${colors['color-neutral-light-whisper']};
  border-radius: 16px;
  padding: 16px;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 24px;

  bds-button:nth-child(2) {
    margin-left: 16px;
  }
`;
