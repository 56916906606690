import Blipsales from '../../assets/Blipsales.svg';
import colors from 'blip-tokens/build/json/colors.json';
import Alert from '../../components/Alert';
import Checkbox from '../../components/Checkbox';
import ConfirmAccountModal from '../../components/ConfirmAccountModal';
import TenantModal from '../../components/TenantModal';
import { VALIDATE_INSTALLATION_EXISTS } from '../../constants/ApiRoutes';
import { ROUTE_INSTALLATION } from '../../constants/Routes';
import { SO_ACTION_ALERT_COMPANY_NAME, SO_NEXT, SO_OPENED, SO_TENANT_SELECTED } from '../../constants/TrackingEvents';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import { Step } from '../../enums';
import { useTrackNoEvent } from 'Self-onboading/hooks/useTrackNoEvent';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createToastError, createToastSuccess, createToastWarning } from '../../services/Toast';
import { saveTermsOfUseSheet } from '../../services/google/Sheets';
import { Center, PageContainer } from '../../styles';
import { Tenant } from '../../typings/Tenant';
import { handleApiErrorResponseForTrack } from '../../utils';
import { CenteredContainer, ContainerActions, ContainerInputLabel, FullWidthContainer } from './styles';
import { constants } from '../../constants';
import { useAuth } from 'oidc-react';

const Welcome: React.FC = () => {
  const { apiService, profile, setActiveStep } = useAppContext();
  const { pack, setPack } = usePackContext();

  const isBlipGoPack =
    pack.name?.includes(constants.blipGoPackNamePROD) || pack.name?.includes(constants.blipGoPackNameHMG);
  const blipGoText = `Iniciaremos o processo de criação e ativação da sua conta Blip Go! Em breve, você terá uma plataforma 
    para criar e gerenciar o chatbot do seu atendimento.`;
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { tracking } = useTrackNoEvent();
  const [isOpenTenantModal, setIsOpenTenantModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirmAccountModal, setIsOpenConfirmAccountModal] = useState(false);
  const [canOpenCompanyNameAlert, setCanOpenCompanyNameAlert] = useState<'yes' | 'no' | 'noMore'>('no');
  const [companyName, setCompanyName] = useState('');
  const companyNameRef = React.useRef<HTMLBdsInputElement>(null);
  const navigate = useNavigate();

  const auth = useAuth();

  const handleChangeCompanyName = (e: any) => setCompanyName(e.target.value);
  const handleEnableNext = () => companyName.trim().length > 1 && companyName.trim().length <= 100;

  const handleSelectedTenant = (tenant: Tenant) => {
    setPack(pack => ({ ...pack, tenant: tenant }));
    tracking(SO_TENANT_SELECTED, { tenant: tenant.id, plan: tenant.plan?.name });
    setIsOpenTenantModal(false);
    createToastSuccess('Contrato escolhido com sucesso!');
    companyNameRef.current?.setFocus();
  };

  const handleCompanyNameAlertResult = (confirmed: boolean) => {
    setCanOpenCompanyNameAlert('noMore');
    tracking(SO_ACTION_ALERT_COMPANY_NAME, {
      merchantName: companyName.trim(),
      action: confirmed ? 'Continuar' : 'Cancelar',
    });

    if (confirmed) {
      setPack({ ...pack, companyName: companyName.trim() });
      navigate(ROUTE_INSTALLATION);
    }
    if (isBlipGoPack) {
      saveTermsOfUseSheet(profile?.FullName as string, profile?.email as string);
    }
  };

  const handleTrackNext = (status: string, error?: string) => {
    tracking(SO_NEXT, {
      screenName: 'Início',
      buttonName: 'Próximo',
      status: status,
      error: error ?? '',
      merchantName: companyName.trim(),
    });
  };

  const handleNext = async () => {
    try {
      setIsLoading(true);

      const companyNameAlreadyExists = await apiService.get(VALIDATE_INSTALLATION_EXISTS, {
        packId: pack.id,
        clientName: companyName,
      });

      if (companyNameAlreadyExists) {
        handleTrackNext('Falha', 'Nome da empresa já existe');
        createToastWarning('Insira um nome diferente', 'Este nome já está em uso.');
        companyNameRef.current?.setFocus();
      } else {
        handleTrackNext('Sucesso');

        if (canOpenCompanyNameAlert == 'no') {
          setCanOpenCompanyNameAlert('yes');
        } else {
          setPack({ ...pack, companyName: companyName.trim() });
          return navigate(ROUTE_INSTALLATION);
        }
      }
    } catch (error) {
      handleTrackNext('Falha', handleApiErrorResponseForTrack(error));
      createToastError('Falha ao processar as informações', 'Por favor, tente novamente daqui alguns minutos.');
    }

    setIsLoading(false);
  };

  const handleChangeTermsAccepted = (e: any) => {
    const value = e.target.checked;

    setTermsAccepted(value);
  };

  useEffect(() => {
    if (auth.userData?.profile.Company) {
      setCompanyName(auth.userData?.profile.Company);
    }

    setActiveStep(Step.Preparation);
    setPack((pack: any) => ({ ...pack, companyName: '' }));
    tracking(SO_OPENED);
    (window as any).gtag('event', 'conversion', { send_to: 'AW-874108198/q8-3CJq68poYEKaq56AD' });
  }, []);

  useEffect(() => {
    if (profile && profile.EmailConfirmed.toLowerCase() === 'true') {
      setIsOpenTenantModal(pack.tenant.id == '');
    } else {
      setIsOpenConfirmAccountModal(true);
    }
  }, []);

  return (
    <>
      <PageContainer>
        <div>
          <bds-typo tag="h4" variant="fs-32" bold="bold">
            Vamos lá?
          </bds-typo>
          <bds-typo tag="h4" variant="fs-14">
            {isBlipGoPack
              ? blipGoText
              : 'Vamos iniciar o processo de criação e configuração do seu pack. Você poderá testá-lo ao final da jornada. 🙂'}
          </bds-typo>
          <br />
          <ContainerInputLabel>
            <bds-typo variant="fs-14">Nome da empresa</bds-typo>
            <bds-tooltip position="right-center" tooltip-text="O nome da sua filial, matriz, apelido ou unidade.">
              <bds-icon
                color={colors['color-extend-grays-street']}
                name="info"
                size="x-small"
                theme="solid"
                aria-label="Ícone de informações"
              ></bds-icon>
            </bds-tooltip>
          </ContainerInputLabel>
          <bds-input
            ref={companyNameRef}
            icon="company"
            value={companyName}
            placeholder="Ex.: Take Blip unidade BH"
            counter-length
            minlength={2}
            minlength-error-message="Este campo deve ter no mínimo 2 caracteres."
            maxlength={100}
            required
            required-error-message="Campo obrigatório."
            onInput={handleChangeCompanyName}
          />
          {isBlipGoPack && (
            <FullWidthContainer>
              <Checkbox onChange={handleChangeTermsAccepted} checked={termsAccepted} />
              <CenteredContainer>
                <bds-typo variant="fs-16" tag="p">
                  &nbsp;Declaro que li e estou de acordo com os&nbsp;
                  <a href="https://www.take.net/docs/termos-de-uso-blip-go/" target="_blank" rel="noreferrer">
                    Termos de Uso
                  </a>
                  .
                </bds-typo>
              </CenteredContainer>
            </FullWidthContainer>
          )}
          <ContainerActions>
            <bds-button
              arrow={true}
              disabled={isLoading || !handleEnableNext() || (!!isBlipGoPack && !termsAccepted)}
              onClick={handleNext}
            >
              Próximo
            </bds-button>
          </ContainerActions>
        </div>
        <div>
          <Center>
            <img src={Blipsales} alt="Blip sales" />
          </Center>
        </div>
      </PageContainer>
      {isOpenTenantModal && <TenantModal isOpen={isOpenTenantModal} onConfirm={handleSelectedTenant} />}
      {isOpenConfirmAccountModal && <ConfirmAccountModal isOpen={isOpenConfirmAccountModal} />}
      {canOpenCompanyNameAlert == 'yes' && (
        <Alert
          isOpen={true}
          bodyText="Não será possível alterar o nome após salvá-lo. Deseja continuar?"
          callback={handleCompanyNameAlertResult}
        />
      )}
    </>
  );
};

export default Welcome;
