import React from 'react';

type AlertProps = {
  isOpen: boolean;
  bodyText: string;
  variant?: 'system' | 'error' | 'warning' | 'delete';
  headerText?: string;
  icon?: string;
  confirmButtonText?: string;
  CancelButtonText?: string;
  callback: (confirmed: boolean) => void;
};

const Alert: React.FC<AlertProps> = props => {
  const getVariant = () => props.variant ?? 'warning';

  const getIcon = () => {
    return (
      props.icon ??
      {
        system: 'info',
        error: 'error',
        warning: 'warning',
        delete: 'trash',
      }[getVariant()]
    );
  };

  return (
    <bds-alert open={props.isOpen}>
      <bds-alert-header variant={getVariant()} icon={getIcon()}>
        {props.headerText ?? 'Atenção!'}
      </bds-alert-header>
      <bds-alert-body>{props.bodyText}</bds-alert-body>
      <bds-alert-actions>
        <bds-button
          variant="secondary"
          onClick={() => {
            props.callback(false);
          }}
        >
          {props.CancelButtonText ?? 'Cancelar'}
        </bds-button>
        <bds-button
          variant="secondary"
          onClick={() => {
            props.callback(true);
          }}
        >
          {props.confirmButtonText ?? 'Continuar'}
        </bds-button>
      </bds-alert-actions>
    </bds-alert>
  );
};

export default Alert;
