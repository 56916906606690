export function InjectHotJarScript() {
  const idScript = 'hotJarScript';
  if (!window.document.getElementById(idScript)) {
    const script = document.createElement('script');
    script.id = idScript;
    script.innerText =
      '(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3161474,hjsv:6}; a=o.getElementsByTagName("head")[0]; r=o.createElement("script");r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,"https://static.hotjar.com/c/hotjar-",".js?sv=");';
    document.head.appendChild(script);
  }
}
