const ExternalUrls = {
  blipchatPrd: 'chat.blip.ai',
  blipchatHmg: 'hmg-chat.blip.ai',
  packManagerPrd: 'https://packs-foods-web-packmanager.cs.blip.ai/pack-manager?packId=76',
  packManagerHmg: 'https://packs-foods-web-packmanager.hmg-packs.blip.ai/pack-manager?packId=402',
  deskPrd: 'https://desk.blip.ai',
  deskHmg: 'https://hmg-desk.blip.ai',
  chatWhatsApp: 'https://wa.me',
  hubspotTicketApiHmg: 'https://whatsappactivation.hmg-cs.blip.ai/api/Hubspot/create-ticket-pipe-embedded-signup',
  hubspotTicketApiPrd: 'https://whatsappactivation.cs.blip.ai/api/Hubspot/create-ticket-pipe-embedded-signup',
};

export default ExternalUrls;
