import Takeblipblacklogo from 'assets/Takeblipblack.svg';
import React from 'react';
import { BrowserView } from 'react-device-detect';
import { Background, Container } from './styles';

const Footer: React.FC = () => {
  return (
    <BrowserView>
      <Background>
        <Container>
          <img src={Takeblipblacklogo} alt="Take Blip" />
          <bds-typo variant="fs-12">
            R. Sergipe. 1440 - Savassi - Belo Horizonte - MG | Alameda Vicente Pinzon, 54 - Vila Olímpia - São Paulo -
            SP
          </bds-typo>
        </Container>
      </Background>
    </BrowserView>
  );
};

export default Footer;
